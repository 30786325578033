header {
    top: 0;
}

p.grident-color-sub-title,
h2.grident-color-sub-title,
h3.grident-color-sub-title,
h4.grident-color-sub-title,
h5.grident-color-sub-title,
h6.grident-color-sub-title,
span.grident-color-sub-title,
div.grident-color-sub-title,
div.grident-color-sub-title h2 {
    color: transparent !important;
    -webkit-background-clip: text !important;
    background: linear-gradient(to right, #7d8dff, #ff4f81);
}

.bg-img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
}

.c-bg-img {
    width: 75%;
    opacity: 85%;
}

@media screen and (max-width: 600px) {
    .c-bg-img {
        width: 100% !important;
    }

    .c-box {
        padding: 20px !important;
    }

    .accordion,
    .cta-content {
        padding: 0 !important;
        margin-left: -25px;
    }

    .cta-content {
        justify-content: center;
    }

    .cta-content .btn-box {
        margin: auto;
    }

    .cta-content h1,
    h3 {
        padding: 0 !important;
        text-align: center;
    }

    .cta-content p {
        padding: 0 !important;
        text-align: center;
    }

    .txt-center-m {
        text-align: center !important;
    }

    .card-wrapper {
        padding: 0px 40px 0px 45px !important;
    }

    .d-border {
        border-bottom: 0px solid !important;
    }

    .ab-containet {
        margin: 0 !important;
    }

    .career-detail {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin: 0 !important;
    }
}

.bg-filter {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: -1;
    object-fit: cover;
    background: #12101078;
}

.device-wrapper {
    max-width: 300px;
    width: 100%;
}

.device {
    position: relative;
    background-size: cover;
}

.device::after {
    position: absolute;
    background-size: cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.device .screen {
    position: absolute;
    background-size: cover;
    pointer-events: auto;
}

.device .button {
    position: absolute;
    cursor: pointer;
}

.device[data-device=iPhoneX][data-orientation=portrait][data-color=black] {
    padding-bottom: 198.898071625%;
}

.device[data-device=iPhoneX][data-orientation=portrait][data-color=black] .screen {
    top: 3.1855955679%;
    left: 6.8870523416%;
    width: 86.2258953168%;
    height: 93.6288088643%;
}

.device[data-device=iPhoneX][data-orientation=portrait][data-color=black]::after {
    content: "";

}

.device[data-device=iPhoneX][data-orientation=portrait][data-color=black] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
}

section.cta {
    position: relative;

    background-position: center;
    background-size: cover;
}

section.cta .cta-content {
    position: relative;
    z-index: 1;
}



.icon-feature {
    font-size: 4rem;
}

.features-device-mockup {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.features-device-mockup .circle {
    position: absolute;
    left: 0;
    fill: url(#circleGradient);
    width: 70%;
    display: none;
}

.features-device-mockup .circle .gradient-start-color {
    stop-color: #2937f0;
}

.features-device-mockup .circle .gradient-end-color {
    stop-color: #9f1ae2;
}

.features-device-mockup .shape-1 {
    position: absolute;
    height: 1rem;
    width: 1rem;
    transform: rotate(10deg);
    fill: #2937f0;
    top: 15%;
    right: 10%;
}

.features-device-mockup .shape-2 {
    position: absolute;
    height: 0.75rem;
    width: 0.75rem;
    fill: #2937f0;
    bottom: 15%;
    left: 10%;
}

.features-device-mockup .device-wrapper {
    max-width: 10rem;
}

@media (min-width: 576px) {
    .features-device-mockup .circle {
        width: 70%;
        display: block;
        left: auto;
    }

    .features-device-mockup .device-wrapper {
        max-width: 12.5rem;
    }
}

@media (min-width: 768px) {
    .features-device-mockup .circle {
        width: 60%;
        left: auto;
    }

    .features-device-mockup .device-wrapper {
        max-width: 15rem;
    }
}

@media (min-width: 992px) {
    .features-device-mockup .circle {
        width: 90%;
        left: -25%;
    }

    .features-device-mockup .shape-1 {
        top: 5%;
        right: -15%;
    }

    .features-device-mockup .shape-2 {
        bottom: 10%;
        left: -10%;
    }

    .features-device-mockup .device-wrapper {
        max-width: 15rem;
        margin-left: auto;
    }
}

@media (min-width: 1200px) {
    .features-device-mockup .circle {
        width: 75%;
        left: 0;
    }

    .features-device-mockup .shape-2 {
        bottom: 15%;
        left: 10%;
    }

    .features-device-mockup .device-wrapper {
        max-width: 15rem;
    }
}

@media (min-width: 1400px) {
    .features-device-mockup .circle {
        width: 80%;
        left: 0;
    }

    .features-device-mockup .device-wrapper {
        max-width: 17rem;
    }
}

footer {
    background: linear-gradient(to right, #00129a, #d5003c) !important;
}

footer a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
}

footer a:hover,
footer a:active,
footer a:focus {
    color: rgba(255, 255, 255, 0.75);
    text-decoration: underline;
}

.masthead {

    height: 100vh;
    padding-top: 9.5rem;
    padding-bottom: 5rem;

}

.img-box-10 {
    height: 100px;
    width: 100px;
    border-radius: 15px;
    background: gray;
    height: 14rem !important;
    width: 14rem;
}

@media(max-width: 500px) {
    .img-box-10 {
        height: 100px;
        width: 100px;
        border-radius: 15px;
        background: gray;
        height: 20rem !important;
        width: 21rem;
    }
}



.img-box-10 img {
    width: 100%;
    height: inherit;
    object-fit: fill;
    background: gray;
}



.ser-card {
    color: inherit;
    cursor: pointer;
    width: calc(25% - 6rem);
    min-width: calc(30% - 6rem);
    height: 400px;
    min-height: 400px;
    perspective: 1000px;
    margin: 1rem;
    position: relative;
    border: none;
}

@media screen and (max-width: 800px) {
    .ser-card {
        width: calc(50% - 2rem);
    }
}

@media screen and (max-width: 500px) {
    .ser-card {
        width: 100%;
    }
}

.front,
.back {
    display: flex;
    border-radius: 6px;
    background-position: center;
    background-size: cover;
    text-align: center;
    justify-content: center;

    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: ease-in-out 600ms;
}

.front {
    background-size: cover;
    padding: 2rem;
    font-size: 1.618rem;
    font-weight: 600;
    color: #fff;
    overflow: hidden;
    font-family: Poppins, sans-serif;
}

@media (max-width:430px) {

    .front,
    .back {

        align-items: center !important;

    }
}


.front p {
    font-size: 26px;
    text-align: center;
    justify-content: center;
    color: white;
    border-radius: 0px;
}

.front:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    opacity: 0.25;
    z-index: -1;
}

.ser-card:hover .front {
    transform: rotateY(180deg);
}

.ser-card:nth-child(even):hover .front {
    transform: rotateY(-180deg);
}

.back {
    transform: rotateY(-180deg);
    padding: 0 2em;
    background: #09090b57;
    border-top: 1px solid #ffffff30;
    border-left: 1px solid #ffffff29;
    border-right: 1px solid #ffffff17;
    border-bottom: 1px solid #ffffff17;
    padding-right: 0;
}

.back ul {
    height: 100%;
    width: 100%;
    text-align: left;
    padding-left: 0;
}

.back ul li {
    list-style: inside;
    padding: 6px 0px;
    font-size: 18px;
    color: white;
    transition: 0.5s;
}


.ser-card:hover .back {
    transform: rotateY(0deg);
}



.process {
    background-repeat: no-repeat;
    background-position: right bottom;
    background-color: #f3f9f9;
    height: 665px;
    margin-top: -40px;
}

.process .row-box {
    margin: 0px 30px;
    padding-top: 20px;
}

.process .row-box .col {
    align-items: center;
    justify-content: center;
}

.process .Process-h {
    padding-top: 50px;
}


.steps {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 205px;
    margin-left: -43px;
}

.steps::after {
    content: "";
    width: 15px;
    height: 15px;
    background: #cf1f31;
    border-radius: 50%;
    position: absolute;
    bottom: -70px;
    left: 35px;
}

.steps .process-icon {
    border: none;
    z-index: 1;
    background-color: white;
    border-radius: 50%;
    height: 67px;
    width: 66px;
    position: absolute;
    left: 10px;
    box-shadow: 1px 2px 4px 0px #3d3d3d5b;
}

.steps .process-icon .Process-img {
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.steps .step-no {
    background-color: #252b42;
    border: none;
    border-radius: 0 40px 40px 0;
    position: absolute;
    width: 103px;
    height: 66px;
    text-align: right;
    padding: 25px;
    color: white;
    box-shadow: 0px 2px 5px 0px #3d3d3d5b;
}

.steps .step-no .step-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
    flex-direction: column;
}

.steps .step-no .step-1 h6 {
    font-size: 18px;
}

.steps .step-no .step-1 span {
    font-size: 30px;
    margin-top: -10px;
}

.d-border {
    border-bottom: dashed #c4c4c4;
    height: 65px;
    margin-left: 50%;
    width: 105%;
    margin-top: 85px;
}

.no-bor {
    border-bottom: none;
    width: 50%;
}





@media (max-width:991px) {
    .tradings-image {
        margin-top: 0px !important;
    }
}



.form-control {
    color: #233242 !important;
}

.form-control::-moz-placeholder {
    opacity: 0.5;
}

.form-control::placeholder {
    opacity: 0.5;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #fff;
    cursor: no-drop;
}

.form-control:not(textarea) {
    height: 45px;

}

textarea.form-control {
    height: 140px;
}

.form-control:focus {
    border-color: #277b8f;
}

.form-group {
    position: relative;
    margin-top: 8px;
}

.form-group label {
    position: absolute;
    background-color: #fff;
    top: -13px;
    left: 12px;
    padding: 0 9px;
}

.contact-info {
    padding-left: 50px;
}

@media (max-width: 991px) {
    .contact-info {
        padding-left: 0;
    }
}

.contact-info .block .icon {
    margin-right: 15px;
    font-size: 18px;
    line-height: 0;
    position: relative;
    top: 5px;
}

.contact-info .block .social-links li {
    margin-right: 15px;
}

.contact-info .block .social-links a {
    font-size: 22px;
}

.newsletter-form {
    margin-top: -35px;
}

@media (max-width: 991px) {
    .newsletter-form {
        margin-top: 0;
    }
}

.newsletter-form input,
.newsletter-form .input-group-text {
    border-color: rgba(81, 181, 109, 0.4) !important;
}

.newsletter-form input {
    border-radius: 8px;
    padding: 0 15px;
}

.newsletter-form .input-group-text {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.newsletter-form button {
    color: #fff;
    border-radius: 0px;
    padding: 19px;
}

.page-header {
    position: relative;
    overflow: hidden;
    padding: 80px 0;
    z-index: 5;
}

.page-header .has-shapes {
    z-index: -1;
}

.page-header .has-shapes .shape {
    height: 170%;
}

.page-header .has-shapes .shape-left {
    left: 50px;
    top: -37%;
    transform: rotate(-35deg);
}

.page-header .has-shapes .shape-right {
    right: 0;
    bottom: 0;
}

.page-header .breadcrumbs,
.page-header .breadcrumbs a {
    color: #111;
}

.page-header .breadcrumbs a:hover {
    color: #51B56D;
}

.page-header .breadcrumbs li:last-child a {
    pointer-events: none;
    opacity: 0.6;
}

.icon-box-item {
    z-index: 3;
}

.icon-box-item .block {
    padding: 35px 27px;

    box-shadow: 0px 25px 65px 0px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
}



.icon-box-item .icon {
    display: inline-block;
    height: 90px;
    width: 90px;
    line-height: 90px;
    font-size: 32px;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
    background-color: #0A0A2E;
}

.core-value .has-shapes {
    z-index: 2;
    pointer-events: none;
}

.core-value .shape-1 {
    right: -14px;
    top: -25px;
}

.core-value .shape-2 {
    left: -30px;
    bottom: -20px;
}


.test-item {
    transition: 0.5s;
}

.test-item:hover {
    transform: translate(0px, -40px);
    box-shadow: 0px 6px 15px 1px #2196f31f;
}

.blog-img {
    height: 220px;
    object-fit: cover;
}

.text-btn {
    transition: 0.4s;
}

.text-btn:hover {
    color: coral;
    border-bottom: 1px dashed;
    cursor: pointer;
}

.shadow-effect img {
    transition: 0.5s;
}

.shadow-effect:hover img {
    transform: scale(1.1);
}

.blog-filter {
    backdrop-filter: grayscale(0);
}

.award {
    background: linear-gradient(82deg, #1c1c82, #9153a5, #f07a7f, #e1e500, #fda14d, #fcbec9, #F5B7A9);
    border-radius: 10px;
    border-color: #e1e500;
    position: relative;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    height: 225px;
}

.award div {
    background: #040024;
}

@media (max-width:430px) {

    .award-2 div {

        height: 310px !important;

    }
}


.award-2 div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 10px;
    border: 2px solid white !important;
    transition: 0.4s;
    height: 240px;

}

.award-1 div {
    border: 2px dashed white !important;
    transition: 0.4s;
    height: 260px;

}

.award-1 div:hover {}

.award-1:hover {}

.changing {
    background: #070034;
}

@media (max-width: 700px) {
    .c-contant-1 {
        margin: 40px 4px 0px 25px;
    }

    .c-contant-text {
        padding: 0 !important;

        margin: -60px 0 20px !important;
    }

    .swiper {
        margin: 0 !important;
    }

    .c-head {
        margin-top: 40px !important;
    }

    .lg-text-center {
        text-align: center !important;
    }

    .sm-h100vh {
        height: 100vh !important;
    }
}

.color-btn {

    width: fit-content;
    height: 50px;
    padding: 0 20px;
    text-align: center;

    color: #fff;
    font-size: 16px;

    text-decoration: none;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 400%;
    border-radius: 10px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.color-btn:hover {
    animation: animate 8s linear infinite;
    color: white;
}

@keyframes animate {
    0% {
        background-position: 0%;
    }

    100% {
        background-position: 400%;
    }
}

.btn-hover::before {
    content: '';
    position: absolute !important;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 400%;
    border-radius: 30px;
    opacity: 0;
}

.btn-hover:hover::before {
    filter: blur(20px);
    opacity: 1;
    animation: animate 8s linear infinite;
}

.nohover:hover::before {
    filter: blur(0px) !important;
    opacity: 0 !important;
}

.btn-hover:hover .rocket {
    transform: scale(2) translate(5px, -7px);
    transition: 0.5s all ease-in-out;
}

.w-fit {
    width: fit-content !important;
}

.glow-text:hover::after {
    box-shadow: 1px 1px 20px 0px #277b8f !important;
}

.ul-list li::before {
    content: "";
    width: 6px;
    height: 6px;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 20px;
    position: relative;
    left: -20px;
    top: 10px;
    display: inline-block;
    vertical-align: top;
    font-size: 5px;
    transform: rotate(45deg);
}

.tech img {
    object-fit: contain;
}



.portfolio {
    padding-bottom: 60px;
}

.portfolio #portfolio-flters {
    padding: 0;
    margin: 0 0 35px 0;
    list-style: none;
    text-align: center;
}

.portfolio #portfolio-flters li {
    cursor: pointer;
    margin: 0 12px 5px 12px;
    display: inline-block;
    padding: 0 4px 6px 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #444;
    transition: all 0.3s ease-in-out;
    border-bottom: 2px solid #fff;
    font-family: "Roboto", sans-serif;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
    border-color: #68A4C4;
    color: #68A4C4;
}

.portfolio .portfolio-item {
    background: #1e4356;
    overflow: hidden;
    min-height: 200px;
    position: relative;
    border-radius: 4px;
    margin: 0 0 30px 0;
}

.portfolio .portfolio-item img {
    transition: all 0.3s ease-in-out;
}

.portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    transition: all 0.3s linear;
    text-align: center;
    top: 10%;
    left: 0;
    right: 0;
}

.portfolio .portfolio-item .portfolio-info h3 {
    font-size: 22px;
    color: #fff;
    font-weight: bold;
}

.portfolio .portfolio-item .portfolio-info a i {
    color: rgba(255, 255, 255, 0.6);
    font-size: 28px;
    transition: 0.3s;
}

.portfolio .portfolio-item .portfolio-info a i:hover {
    color: white;
}

.portfolio .portfolio-item:hover img {
    opacity: 0.6;
    transform: scale(1.1);
}

.portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
    top: calc(50% - 30px);
}



.portfolio-details {
    padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
    width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #68A4C4;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #68A4C4;
}

.portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(30, 67, 86, 0.08);
}

.portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
}

.portfolio-details .portfolio-description {
    padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
    padding: 0;
}

.offcanvas {
    margin: 10px;
    border-radius: 10px;
}

.fs-12 {
    font-size: 12px !important;
}

.bg-main {
    background: #0A0A2E !important;
}

.bg-lightl {
    background: #cacaca21 !important;
}

.text-main {
    color: #0A0A2E !important;
}



.logo {
    width: 12rem;
}



#container-header {
    height: 70px;
    width: 100vw;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    z-index: 999;
}



.change-color {
    backdrop-filter: blur(6px);
    background: #1d1c1e69 !important;
}





@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateX(-30px);
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

.fade-in {
    opacity: 0;
    animation: fadeIn cubic-bezier(0.165, 0.84, 0.44, 1) 1;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
}

.fade-in.one {
    animation-delay: 0.2s;
}

.fade-in.two {
    animation-delay: 0.5s;
}

.fade-in.three {
    animation-delay: 0.8s;
}

@keyframes slideup {
    from {
        opacity: 0;
        transform: translateY(30px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes slidebottom {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

.slide-in {
    opacity: 0;
    animation: slideup cubic-bezier(0.165, 0.84, 0.44, 1) 1;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
}

.slide-bottom {
    opacity: 0;
    animation: slidebottom cubic-bezier(0.165, 0.84, 0.44, 1) 1;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
}

.slide-in.slide-bottom.one {
    animation-delay: 0.2s;
}

.slide-in.slide-bottom.two {
    animation-delay: 0.5s;
}

.slide-in.slide-bottom.three {
    animation-delay: 0.8s;
}

.parellex-img {
    position: fixed;
    z-index: -3;
}

.g-text {
    background: linear-gradient(to right, #7d8dff, #ff4f81) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    display: inline-block !important;
}

.rounded-10 {
    border-radius: 10px !important;
}

.text-trun {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.c-head {
    line-height: 1;
}

.shadow-light {
    box-shadow: -1px 11px 20px 0px #f0f0f0;
}

.cta-content-img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

.cta-content-img1 {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

.bg-filter-dark {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: -1;
    object-fit: cover;
    background: #0c0332d1;
}

.g-text-h {
    height: 120px;
}

.h-180 {
    height: 180px !important;
}

.obj-none {
    object-fit: none !important;
}

.height-100vh {
    height: 100vh !important;
}

.height-25 {
    height: 25px !important;
}

.h-40 {
    height: 40px;
}

.search-box {
    display: flex;
    line-height: 28px;
    align-items: center;
    position: relative;
}

.search-box .input {
    width: 100%;
    height: 40px;
    line-height: 28px;
    padding: 0 1rem;
    padding-left: 2.5rem;
    border: 2px solid transparent;
    border-radius: 8px;
    outline: none;
    background-color: #f3f3f4;
    color: #0d0c22;
    transition: .3s ease;
}

.search-box .input::placeholder {
    color: #9e9ea7;
}

.search-box .input:focus,
input:hover {
    outline: none;
    border-color: rgb(54 0 182 / 50%);
    background-color: #fff;
    box-shadow: 0 0 0 4px rgb(54 0 182 / 10%);
}

.search-box .icon {
    position: absolute;
    left: 1rem;
    fill: #9e9ea7;
    width: 1rem;
    height: 1rem;
}

.border-light {
    border: 1px solid #80808024 !important;
}

.px-5p {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
}

.gradientBackground {
    background: linear-gradient(90deg, rgba(46, 15, 134, 1) 0%, rgba(209, 1, 62, 1) 100%);
}



process_section .line_bg {
    position: relative;
    padding-bottom: 160px;
    background-size: 50% 100%;
    background-position: left 15px bottom;
}

.process_section .line_bg:after,
.process_section .line_bg:before {
    left: 51.4%;
    content: "";
    position: absolute;
    border-radius: 100%;
    -webkit-transform: translateX(-51.4%);
    -ms-transform: translateX(-51.4%);
    transform: translateX(-51.4%);
}

.process_section .line_bg:before {
    width: 20px;
    height: 20px;
    bottom: -8px;
    background-color: rgba(65, 84, 241, 0.16);
}

.process_section .line_bg:after {
    width: 8px;
    height: 8px;
    bottom: -2px;
    background-color: #4154f1;
}

.process_section .process_item {
    position: relative;
    padding-left: 65px;
    margin-bottom: 60px;
    padding-bottom: 100px;
}

.process_section .process_item:last-child {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.process_section .process_item .item_image {
    margin: 0 auto;
    max-width: 440px;
}

.process_section .process_item .item_content {
    position: relative;
}

.process_section .process_item .item_content:before {
    top: -9px;
    left: -74px;
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 100%;
    background-color: rgba(65, 84, 241, 0.16);
}

.process_section .process_item .item_content:after {
    top: -3px;
    left: -68px;
    width: 8px;
    height: 8px;
    content: "";
    position: absolute;
    border-radius: 100%;
    background-color: #4154f1;
}

.process_section .process_item:nth-child(2n + 0) .item_content:before {
    left: -70px;
}

.process_section .process_item:nth-child(2n + 0) .item_content:after {
    left: -64px;
}

.process_section .process_item .serial_number {
    line-height: 1;
    font-size: 60px;
    margin-top: -35px;
    margin-bottom: 45px;
    letter-spacing: 5px;
    display: inline-block;
    font-family: sofia pro bold;
    text-shadow: -5px 5px 0 #eceefe;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #cdcecf;
    -webkit-text-fill-color: transparent;
}

.process_section .process_item .item_title {
    font-size: 36px;
    margin-bottom: 25px;
}

.process_section .process_item .details_btn {
    width: 50px;
    height: 50px;
    color: #fff;
    font-size: 22px;
    line-height: 52px;
    text-align: center;
    border-radius: 100%;
    -webkit-box-shadow: 0 10px 20px 0 rgba(62, 92, 255, 0.2);
    box-shadow: 0 10px 20px 0 rgba(62, 92, 255, 0.2);
    background-image: -webkit-linear-gradient(45deg, #301cab 0%, #4448d3 52%, #5774fa 100%);
    background-image: -o-linear-gradient(45deg, #301cab 0%, #4448d3 52%, #5774fa 100%);
    background-image: linear-gradient(45deg, #301cab 0%, #4448d3 52%, #5774fa 100%);
}





@media screen and (max-width: 767px) {
    .btn_text {
        font-size: 16px;
    }

    .btn {
        font-size: 15px;
        padding: 15px 26px;
    }

    .btn.btn_border {
        padding: 14px 26px;
    }

    .mb-80 {
        margin-bottom: 40px;
    }

    .form_item input {
        height: 50px;
    }

    .form_item .input_title {
        margin-bottom: 8px;
    }

    .form_item.dropdown>button {
        height: 50px;
    }

    .form_item.dropdown .dropdown-menu>ul>li>a {
        font-size: 14px;
        padding: 10px 30px;
    }

    .header_section .brand_logo>.brand_link {
        max-width: 115px;
    }

    .header_section .menu_btn {
        font-size: 25px;
        margin: 7px 0;
    }

    .footer_section .footer_bottom {
        text-align: center;
    }

    .footer_section .copyright_text {
        margin-bottom: 15px;
    }

    .footer_section .footer_menu>ul {
        float: none;
        margin: 15px auto 0;
    }

    .breadcrumb_section .breadcrumb_content .page_title {
        font-size: 48px;
    }

    .sidebar_section.pl-30 {
        padding: 0;
    }

    .error_section .error_content p {
        max-width: 590px;
    }

    .error_section .spahe_1,
    .error_section .spahe_2 {
        max-width: 330px;
    }

    .error_section .error_content .page_title {
        font-size: 250px;
    }

    .error_section .error_content .title_text {
        font-size: 38px;
    }

    .service_boxed_1 {
        max-width: 370px;
        margin: 30px auto 0;
    }

    .service_section .spahe_1 {
        right: 70px;
        max-width: 140px;
    }

    .service_section .spahe_2 {
        right: 15px;
        max-width: 130px;
    }

    .newsletter_section .newsletter_boxed .shape_1 {
        bottom: 60px;
        max-width: 140px;
    }

    .newsletter_section .newsletter_boxed .shape_2 {
        right: 40px;
        bottom: 130px;
        max-width: 80px;
    }

    .newsletter_section .newsletter_boxed {
        padding: 60px;
    }

    .service_section .tab-content .service_image_2 .image_2 {
        right: 0;
        max-width: 190px;
    }

    .introduction_section .video_wrap {
        max-width: 450px;
        margin: 0 auto;
    }

    .introduction_section .video_wrap .shadow_image {
        width: 145%;
    }

    .introduction_section .video_wrap {
        padding-bottom: 40px;
    }

    .introduction_section .video_wrap .dot_image {
        top: -20px;
        right: -18px;
        max-width: 90px;
    }

    .introduction_section .video_wrap .leaf_image {
        left: -50px;
        bottom: 15px;
        max-width: 75px;
    }

    .introduction_section .video_wrap .man_1 {
        right: -80px;
        bottom: 40px;
        max-width: 70px;
    }

    .introduction_section .video_wrap .man_2 {
        left: -100px;
        bottom: 45px;
        max-width: 90px;
    }

    .feature_section .child_feature p {
        font-size: 14px;
    }

    .service_primary_2 {
        max-width: 330px;
        margin: 50px auto 0;
    }

    .owl-theme.arrow_top_right .owl-nav {
        top: -70px;
    }

    .feature_section .section_title .tabs_nav>ul>li>a {
        font-size: 15px;
        padding: 10px 22px;
    }

    .feature_section .section_title .tabs_nav>ul {
        margin: 0 auto;
    }

    .owl-theme.arrow_top_right .owl-nav {
        top: -60px;
    }

    .register_section .signup_wrap {
        background: 0 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0 30px;
    }

    .register_section .signup_wrap .info_wrap,
    .register_section .signup_wrap .signup_form {
        margin: 0;
        padding: 0;
        border: none;
    }

    .register_section .signup_wrap .info_wrap {
        max-width: 380px;
        margin-bottom: 60px;
    }

    .process_section .line_bg {
        background: 0 0 !important;
        padding-bottom: 0;
    }

    .process_section .line_bg:after,
    .process_section .line_bg:before {
        display: none;
    }

    .process_section .process_item {
        margin: 0;
        padding: 0;
        margin-bottom: 70px;
    }

    .process_section .process_item:last-child {
        margin-bottom: 0 !important;
    }

    .process_section .process_item .order-last {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }

    .process_section .process_item .item_content {
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .process_section .process_item .item_content:after,
    .process_section .process_item .item_content:before {
        display: none;
    }

    .process_section .process_item .serial_number {
        margin-top: 0;
        margin-bottom: 35px;
    }

    .process_section .process_item .item_image {
        max-width: 440px;
        margin: 0 auto 30px;
    }

    .pricing_plan_1,
    .pricing_plan_2,
    .pricing_plan_3 {
        max-width: 340px;
        margin: 30px auto 0;
    }

    .apply_section .apply_form .form_item textarea {
        min-height: 150px;
    }

    .map_section .google-map {
        height: 360px;
    }

    .filter-btns-group {
        margin-bottom: 30px;
    }

    .casestudy_section .element_grid {
        margin: -7px;
    }

    .casestudy_section .element_grid .element-item {
        padding: 7px;
    }

    .casestudy_fullimage .item_content .item_title>a {
        font-size: 16px;
    }

    .casestudy_fullimage .item_content .item_category>a {
        font-size: 13px;
    }

    .filter-btns-group>ul>li {
        height: 40px;
    }

    .filter-btns-group>ul>li>button {
        font-size: 14px;
        padding: 0 25px;
        line-height: 40px;
    }

    .details_section .item_image.mr--30 {
        margin: 0;
        margin-bottom: 30px;
    }

    .details_section .item_image.mr--30>img {
        width: 100%;
        display: block;
    }

    .details_section .item_title {
        font-size: 30px;
        margin-bottom: 30px;
    }

    .agency_banner .bg_1 {
        width: 675px;
    }

    .agency_banner .bg_2 {
        width: 795px;
    }

    .feature_section .shape_1 {
        left: -180px;
        max-width: 500px;
    }

    .testimonial_section .shape_2 {
        max-width: 300px;
    }

    .partner_section .shape_1 {
        max-width: 150px;
    }

    .app_landing_banner .banner_content .title_text {
        font-size: 36px;
        margin-bottom: 15px;
    }

    .banner_section .banner_content p {
        font-size: 13px;
        margin-bottom: 20px;
    }

    .app_landing_banner .banner_image .phone_1 {
        max-width: 270px;
    }

    .app_landing_banner .banner_image .phone_2 {
        max-width: 224px;
        right: -35px;
    }

    .app_landing_banner .banner_image .shape_1 {
        width: 375px;
        top: 0;
        left: -25px;
    }

    .service_section .section_title {
        text-align: center;
    }

    .service_section .section_title.mr--30 {
        margin-right: 0;
    }

    .appScreenshot {
        max-width: 530px;
        margin: 0 auto;
    }

    .showcase_section .mobile_frame {
        width: 265px;
    }

    .chat_banner .banner_content .title_text {
        font-size: 32px;
        margin-bottom: 15px;
    }

    .chat_banner .banner_image {
        max-width: 240px;
    }

    .chat_banner {
        padding-top: 120px;
    }

    .chat_banner .banner_image .bar_image {
        max-width: 180px;
    }

    .chat_banner .banner_image .bar_1 {
        top: 55px;
        left: -45px;
    }

    .chat_banner .banner_image .bar_2 {
        top: 155px;
        right: -80px;
    }

    .chat_banner .banner_image .bar_3 {
        top: 220px;
        left: -45px;
    }

    .chat_banner .bg_1,
    .chat_banner .bg_2 {
        max-width: 435px;
    }

    .partner_section .dot_image_1 {
        display: none;
    }

    .introduction_section .video_wrap .leaf_image_1 {
        left: -70px;
    }

    .introduction_section .video_wrap .leaf_image_2 {
        right: -70px;
    }

    .feature_section .feature_image_5 {
        max-width: 340px;
    }

    .feature_section .feature_image_5 .image_2,
    .feature_section .feature_image_5 .image_3 {
        max-width: 220px;
    }

    .feature_section .feature_image_5 .image_2 {
        bottom: 40px;
        left: -105px;
    }

    .feature_section .feature_image_5 .image_3 {
        top: -90px;
        right: -65px;
    }

    .feature_section .feature_image_5 .dot_image_1,
    .feature_section .feature_image_5 .dot_image_2 {
        max-width: 100px;
    }

    .feature_section .feature_image_5 .dot_image_2 {
        right: 10px;
        bottom: 125px;
    }

    .feature_section .shape_12 {
        top: 125px;
        max-width: 595px;
    }

    .banner_section .banner_content {
        max-width: 380px;
    }

    .banner_section .banner_content .title_text {
        font-size: 32px;
        margin-bottom: 15px;
    }

    .digital_agency_banner .banner_image {
        max-width: 350px;
    }

    .digital_agency_banner .shape_1 {
        top: -86px;
        right: -135px;
        max-width: 500px;
    }

    .digital_agency_banner .shape_2 {
        top: -110px;
        right: -190px;
        max-width: 570px;
    }

    .digital_marketing_banner .banner_image {
        max-width: 330px;
    }

    .digital_marketing_banner .apps_wrap>ul {
        width: 200px;
        height: 180px;
    }

    .digital_marketing_banner .apps_wrap {
        bottom: 190px;
    }

    .digital_marketing_banner .apps_wrap>ul>li {
        width: 45px;
        height: 45px;
    }

    .digital_marketing_banner .apps_wrap>ul>li>a>img {
        height: 18px;
    }

    .seo_section .shape_1 {
        max-width: 90px;
    }

    .seo_section .glob_image_1 {
        top: -10px;
        max-width: 280px;
    }

    .hr_management_banner .shape_1 {
        max-width: 250px;
    }

    .hr_management_banner .shape_2 {
        max-width: 370px;
    }

    .hr_management_banner .shape_3 {
        max-width: 480px;
    }

    .hr_management_banner .banner_image {
        top: -60px;
        right: -450px;
        -webkit-transform: scale(0.46);
        -ms-transform: scale(0.46);
        transform: scale(0.46);
    }

    .hr_management_banner {
        min-height: auto;
        padding-top: 200px;
        padding-bottom: 140px;
    }

    .hr_management_banner .shape_5 {
        left: 35%;
        bottom: -35%;
    }

    .hr_management_banner .shape_4 {
        top: 30px;
        left: 48%;
    }

    .hr_management_banner .shape_1 {
        max-width: 220px;
    }

    .hr_management_banner .shape_2 {
        max-width: 340px;
    }

    .hr_management_banner .shape_3 {
        max-width: 450px;
    }

    .intregation_section .apps_wrap>ul {
        width: auto;
        height: auto;
    }

    .intregation_section .apps_wrap>ul>li {
        float: none;
        display: block;
        position: static;
        margin-bottom: 15px;
    }

    .payment_banner .shape_1 {
        left: -100px;
        bottom: -50px;
        max-width: 340px;
    }

    .feature_section .child_feature {
        max-width: 320px;
        margin: 50px auto 0;
    }

    .feature_section .shape_9 {
        right: -100px;
        max-width: 320px;
    }

    .feature_section .shape_10 {
        left: -100px;
        max-width: 320px;
    }

    .feature_boxed {
        padding: 35px;
        max-width: 320px;
        padding-top: 65px;
        margin: 60px auto 0;
    }

    .project_management_banner .banner_content {
        max-width: 410px;
    }

    .project_management_banner .banner_image {
        width: auto;
        max-width: 630px;
        margin: 80px auto -100px;
    }

    .banner_section .form_item input {
        height: 50px;
        padding: 0 20px;
    }

    .project_management_banner {
        margin-bottom: 100px;
    }

    .project_management_banner .banner_image .deco_image {
        top: 80px;
        right: 33px;
        max-width: 150px;
    }

    .service_section .tabs_nav_2 {
        margin-bottom: 50px;
    }

    .service_section .tab-content .service_image_3 {
        margin-bottom: -50px;
    }

    .saas_banner .banner_content {
        max-width: 100% !important;
    }

    .saas_banner .banner_image {
        max-width: 570px;
        margin: 0 auto -120px;
    }

    .saas_banner {
        padding-top: 150px;
        margin-bottom: 80px;
    }

    .saas_banner .banner_image .phone_image {
        max-width: 110px;
    }

    .h_map {
        margin-left: -15px;
        margin-right: -15px;
    }

    .h_map ul li .place_name {
        font-size: 10px;
        min-width: auto;
        padding: 0 10px;
        line-height: 20px;
    }

    .h_map ul li:nth-child(1) {
        top: 5%;
        left: 5%;
    }

    .h_map ul li:nth-child(2) {
        top: 30%;
        left: 10%;
    }

    .h_map ul li:nth-child(3) {
        left: 50%;
        top: 20%;
    }

    .h_map ul li:nth-child(4) {
        top: 40%;
        left: 73%;
    }

    .h_map ul li:nth-child(5) {
        top: 10%;
        left: 88%;
    }

    .h_map ul li:nth-child(6) {
        top: 70%;
        left: 15%;
    }

    .h_map ul li:nth-child(7) {
        top: 56%;
        left: 52%;
    }

    .h_map ul li:nth-child(8) {
        top: 79%;
        left: 96%;
    }

    .details_section .details_images_carousel {
        margin-bottom: 30px;
    }

    .gallery_section .element_grid.column_3 .element-item,
    .casestudy_section .element_grid.column_3 .element-item,
    .portfolio_section .element_grid.column_3 .element-item {
        width: 50%;
    }

    .home_chat:not(.home_digital_agency) .footer_section .useful_links,
    .home_chat:not(.home_digital_agency) .footer_section .social_links {
        margin-top: 40px;
        text-align: center;
    }

    .home_chat:not(.home_digital_agency) .widget.about_content {
        margin: 0 auto;
        text-align: center;
    }

    .banner_section .banner_content {
        max-width: 380px;
        margin: 0 auto;
    }

    .service_section .section_title .c_slide_in_wrap1,
    .feature_section .section_title .c_slide_in_wrap1,
    .feature_section .section_title .sub_title {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .hr_management_banner .banner_content {
        margin: 0 !important;
    }

    .project_management_banner .banner_content .title_text {
        font-size: 36px;
    }

    .project_management_banner .banner_content p {
        font-size: 14px;
    }

    .project_management_banner .banner_content {
        max-width: 430px;
        margin: 0 auto;
    }

    .saas_banner .banner_content {
        margin: 0 auto;
        max-width: 480px !important;
    }

    .saas_banner .banner_content p {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .saas_banner .banner_content .title_text {
        margin-bottom: 10px;
    }

    .banner_section .banner_content .title_text {
        font-size: 42px;
    }

    .digital_marketing_banner .banner_content .title_text {
        font-size: 32px;
    }

    .text_effect {
        line-height: 1;
    }

    .banner_section .banner_content p {
        font-size: 14px;
    }

    .digital_marketing_banner .banner_image {
        max-width: 330px;
        margin-top: 40px;
    }

    .payment_banner .banner_content .title_text {
        font-size: 40px;
    }

    .software_banner .banner_content .title_text {
        font-size: 38px;
    }

    .section_title .sub_title {
        font-size: 15px;
    }

    .section_title .title_text,
    .section_title.increase_size .title_text,
    .feature_section .section_title .title_text,
    .feature_section .section_title.increase_size .title_text {
        font-size: 30px;
    }

    .section_title p {
        font-size: 15px;
    }

    .header_section .brand_logo>.brand_link,
    .sticky_header.stuck .brand_logo>.brand_link {
        max-width: 115px;
    }

    .banner_section .form_item .btn {
        font-size: 14px;
        padding: 12px 26px;
    }
}

@media screen and (max-width: 710px) {
    .agency_banner .bg_2 {
        width: 725px;
    }

    .agency_banner .bg_1 {
        width: 625px;
    }

    .banner_section .banner_content .title_text {
        font-size: 40px;
    }
}

@media screen and (max-width: 700px) {
    .job_item .btn {
        min-width: auto;
        position: -webkit-sticky;
        position: sticky;
        -webkit-transform: unset;
        -ms-transform: unset;
        transform: unset;
        font-size: 14px;
        margin-top: 20px;
    }

    .job_section .button-group>ul>li {
        height: 40px;
    }

    .job_section .button-group>ul>li>button {
        font-size: 14px;
        padding: 0 25px;
        line-height: 40px;
    }

    .job_section .button-group {
        margin-bottom: 20px;
    }

    .gallery_section .element_grid.column_2 .element-item,
    .casestudy_section .element_grid.column_2 .element-item,
    .portfolio_section .element_grid.column_2 .element-item {
        width: 50%;
    }

    .feature_section .feature_image_3 .image_2 {
        max-width: 240px;
        top: 85px;
        left: -70px;
    }

    .banner_section .banner_content .title_text {
        font-size: 30px;
    }

    .digital_agency_banner .banner_image {
        max-width: 310px;
    }

    .digital_agency_banner .shape_1 {
        max-width: 455px;
    }

    .digital_agency_banner .shape_2 {
        max-width: 525px;
    }

    .project_management_banner .banner_content .title_text {
        font-size: 34px;
    }

    .banner_section .banner_content .title_text {
        font-size: 38px;
    }

    .digital_marketing_banner .banner_content .title_text {
        font-size: 34px;
    }

    .hr_management_banner .banner_content .title_text span {
        font-size: 40px;
    }
}


@media screen and (max-width: 880px) {
    .section_title .title_text {
        font-size: 32px;
    }

    .section_title p {
        font-size: 14px;
    }

    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }

    .btn {
        padding: 17px 35px;
    }

    .btn.btn_border {
        padding: 16px 35px;
    }

    .section_title .title_text.mb-30 {
        margin-bottom: 20px;
    }

    .section_title.c_slide_in .title_text {
        line-height: 1.1;
    }

    .breadcrumb_section .breadcrumb_content p {
        font-size: 14px;
    }

    .breadcrumb_section {
        min-height: 380px;
    }

    .service_boxed_1 .item_icon {
        width: 70px;
        height: 70px;
        font-size: 40px;
        line-height: 70px;
    }

    .service_boxed_1 .icon_wrap {
        margin-bottom: 20px;
    }

    .service_boxed_1 .item_title {
        margin-bottom: 15px;
    }

    .service_boxed_1 .item_title>a {
        font-size: 20px;
    }

    .service_boxed_1 p {
        font-size: 13px;
        margin-bottom: 20px;
    }

    .service_boxed_1 {
        padding: 40px 30px;
    }

    .service_boxed_1 .details_btn {
        font-size: 42px;
    }

    .partner_section .partner_logo {
        height: 40px;
        margin-top: 30px;
    }

    .partner_section .partner_logo>a {
        max-width: 105px;
    }

    .service_primary {
        padding: 0;
        max-width: 230px;
        text-align: center;
        margin: 50px auto 0;
    }

    .service_section .tabs_nav>ul {
        width: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .service_section .tabs_nav>ul>li {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333%;
        flex: 0 0 33.333%;
        max-width: 33.333%;
    }

    .service_section .tabs_nav>ul>li>a {
        padding: 40px 30px;
    }

    .service_section .tab-content .service_image_2 .image_1 {
        max-width: 670px;
        margin: 0 auto -54px;
    }

    .service_section .tab-content .service_image_2 .image_2 {
        top: 80px;
        max-width: 250px;
    }

    .testimonial_section .testimonial_carousel_2 .testimonial_item {
        max-width: 470px;
        margin: 0 auto;
    }

    .testimonial_section .bg_area {
        height: 390px;
    }

    .register_section .signup_wrap .info_wrap,
    .register_section .signup_wrap .signup_form {
        padding: 0 45px;
    }

    .register_section .signup_wrap {
        padding: 50px 0;
    }

    .register_section .signup_wrap .info_wrap h3 {
        font-size: 26px;
        margin-bottom: 30px;
    }

    .register_section .signup_wrap .info_wrap h4 {
        font-size: 20px;
        margin-bottom: 40px;
    }

    .process_section .process_item:nth-child(2n) .item_content::after {
        left: -58px;
    }

    .process_section .process_item:nth-child(2n) .item_content::before {
        left: -64px;
    }

    .process_section .process_item .item_title {
        font-size: 28px;
    }

    .process_section .process_item .serial_number {
        margin-bottom: 35px;
    }

    .job_item .item_content {
        padding-top: 0;
    }

    .job_item .item_title>a {
        font-size: 18px;
    }

    .job_item p {
        font-size: 13px;
    }

    .blog_grid .item_title>a {
        font-size: 22px;
    }

    .blog_grid .details_btn {
        font-size: 16px;
    }

    .blog_quote p a {
        font-size: 16px;
    }

    .service_section .dot_image_1 {
        top: -130px;
        left: -60px;
        max-width: 100px;
    }

    .feature_section .shape_2 {
        top: 40px;
        max-width: 100px;
    }

    .introduction_section .video_wrap {
        padding-bottom: 45px;
    }

    .app_landing_banner .banner_content .title_text {
        font-size: 42px;
    }

    .app_landing_banner .banner_content {
        margin-top: -100px;
    }

    .service_boxed_3 {
        padding: 35px 30px;
    }

    .feature_section .feature_image_4 {
        max-width: 420px;
    }

    .feature_section .feature_image_4 .phone_image {
        max-width: 370px;
        margin-left: -90px;
    }

    .feature_section .feature_image_4 .chat_image {
        max-width: 300px;
        top: 50px;
    }

    .feature_section .shape_6 {
        max-width: 250px;
    }

    .feature_section .dot_1 {
        top: 180px;
        right: 100px;
    }

    .feature_section .dot_2 {
        top: 340px;
        left: 40px;
    }

    .showcase_section .mobile_frame {
        width: 295px;
    }

    .chat_banner .banner_image {
        max-width: 300px;
        margin: 0 auto;
    }

    .chat_banner .banner_image .bar_image {
        max-width: 220px;
    }

    .chat_banner .banner_image .bar_1 {
        top: 80px;
        left: -50px;
    }

    .chat_banner .banner_image .bar_2 {
        top: 190px;
        right: -95px;
    }

    .chat_banner .banner_image .bar_3 {
        top: 280px;
        left: -50px;
    }

    .chat_banner .bg_1,
    .chat_banner .bg_2 {
        max-width: 530px;
    }

    .chat_banner .bg_1 {
        top: -95px;
        right: -100px;
    }

    .chat_banner .bg_2 {
        top: -65px;
    }

    .banner_section .banner_content .title_text {
        font-size: 36px;
        margin-bottom: 15px;
    }

    .banner_section .banner_content p {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .digital_agency_banner .banner_image {
        max-width: 380px;
    }

    .digital_agency_banner .shape_1 {
        top: -90px;
        right: -140px;
        max-width: 535px;
    }

    .digital_agency_banner .shape_2 {
        top: -110px;
        right: -190px;
        max-width: 600px;
    }

    .digital_agency_banner {
        min-height: auto;
        padding-top: 200px;
        padding-bottom: 130px;
    }

    .digital_marketing_banner .banner_content {
        max-width: 470px;
        margin: 0 auto 40px;
    }

    .digital_marketing_banner .banner_content p {
        font-size: 13px;
        margin-bottom: 20px;
    }

    .banner_section .banner_content .btns_group>ul>li {
        margin-right: 15px;
    }

    .digital_marketing_banner .banner_image {
        max-width: 370px;
    }

    .digital_marketing_banner {
        min-height: auto;
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .digital_marketing_banner .apps_wrap>ul {
        width: 230px;
        height: 210px;
    }

    .digital_marketing_banner .apps_wrap {
        bottom: 230px;
    }

    .digital_marketing_banner .apps_wrap>ul>li {
        width: 50px;
        height: 50px;
    }

    .digital_marketing_banner .apps_wrap>ul>li>a>img {
        height: 24px;
    }

    .digital_marketing_banner .cloud_image {
        max-width: 50px;
    }

    .digital_marketing_banner .cloud_image.cloud_1 {
        top: 25%;
        left: 5%;
    }

    .digital_marketing_banner .triangle_1 {
        top: 14%;
        left: 14%;
    }

    .digital_marketing_banner .dot_1 {
        top: 36%;
        left: 14%;
    }

    .digital_marketing_banner .dot_2 {
        top: 48%;
        left: 2%;
    }

    .digital_marketing_banner .dot_3 {
        top: 40%;
        left: 30%;
    }

    .digital_marketing_banner .cloud_image.cloud_2 {
        top: 12%;
        right: 4%;
    }

    .digital_marketing_banner .dot_4 {
        top: 20%;
        right: 3%;
    }

    .digital_marketing_banner .triangle_2 {
        top: 20%;
        right: 20%;
    }

    .digital_marketing_banner .cloud_image.cloud_3 {
        top: 30%;
    }

    .digital_marketing_banner .cloud_image.cloud_4 {
        top: 40%;
        right: 22%;
    }

    .digital_marketing_banner .dot_5 {
        top: 32%;
        right: 15%;
    }

    .digital_marketing_banner .dot_6 {
        top: 47%;
        right: 5%;
    }

    .home_digital_marketing .service_primary {
        padding: 0;
    }

    .service_primary .item_title {
        margin-bottom: 15px;
    }

    .service_primary .item_title>a {
        font-size: 22px;
    }

    .service_primary .icon_wrap {
        margin-bottom: 20px;
    }

    .service_primary .item_icon {
        width: 75px;
        height: 75px;
        font-size: 42px;
        line-height: 75px;
    }

    .service_primary p {
        font-size: 13px;
    }

    .home_digital_marketing .section_title .icon_rounded {
        width: 70px;
        height: 70px;
        line-height: 70px;
    }

    .home_digital_marketing .pricing_section {
        padding-bottom: 120px;
    }

    .hr_management_banner .banner_content .title_text span {
        font-size: 36px;
    }

    .hr_management_banner .banner_content {
        max-width: 390px;
    }

    .hr_management_banner .banner_image {
        right: -415px;
        -webkit-transform: scale(0.54);
        -ms-transform: scale(0.54);
        transform: scale(0.54);
    }

    .hr_management_banner {
        min-height: 655px;
    }

    .hr_management_banner .shape_1 {
        max-width: 255px;
    }

    .hr_management_banner .shape_2 {
        max-width: 405px;
    }

    .hr_management_banner .shape_3 {
        max-width: 545px;
    }

    .banner_section .banner_content .popup_video .icon {
        width: 45px;
        height: 45px;
        font-size: 32px;
        line-height: 45px;
    }

    .banner_section .banner_content .popup_video small {
        margin: 9px 0;
    }

    .hr_management_banner .shape_4 {
        top: 50px;
        left: 45%;
    }

    .hr_management_banner .shape_5 {
        left: 30%;
        bottom: -140px;
    }

    .hr_management_banner .shape_4,
    .hr_management_banner .shape_5 {
        max-width: 18px;
    }

    .saas_banner .banner_image {
        width: auto;
        max-width: 670px;
        margin: 0 auto -164px;
    }

    .saas_banner .banner_image .phone_image {
        right: -20px;
        bottom: -12px;
        max-width: 125px;
    }

    .feature_section .shape_14 {
        top: 0;
        left: -340px;
        max-width: 680px;
    }

    .feature_section .shape_15 {
        right: -209px;
        bottom: 100px;
        max-width: 600px;
    }

    .feature_section .feature_image_12 .big_image {
        margin-top: -19px;
        margin-bottom: 0;
    }

    .feature_section .feature_image_12 {
        margin: 0 auto 40px;
    }

    .feature_section .feature_image_12 .small_image {
        max-width: 170px;
    }

    .feature_section .feature_image_12 .child_image_1,
    .feature_section .feature_image_12 .child_image_2,
    .feature_section .feature_image_12 .child_image_3 {
        max-width: 200px;
    }

    .feature_section .feature_image_12 .child_image_1 {
        top: 30px;
    }

    .feature_section .feature_image_12 .child_image_2 {
        top: 83px;
    }

    .feature_section .feature_image_12 .child_image_3 {
        max-width: 240px;
        top: 135px;
    }

    .casestudy_fullimage .item_content {
        padding: 20px;
        padding-bottom: 15px;
    }

    .casestudy_fullimage .details_btn {
        top: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        font-size: 18px;
        line-height: 42px;
    }

    .gallery_section .element_grid.masonry_portfolio.column_4 .element-item,
    .casestudy_section .element_grid.masonry_portfolio.column_4 .element-item,
    .portfolio_section .element_grid.masonry_portfolio.column_4 .element-item {
        width: 33.333%;
    }

    .gallery_section .element_grid.column_4 .element-item,
    .casestudy_section .element_grid.column_4 .element-item,
    .portfolio_section .element_grid.column_4 .element-item {
        width: 33.333%;
    }

    .digital_marketing_banner .banner_content p {
        font-size: 14px;
    }

    .banner_section .banner_content .title_text {
        font-size: 38px;
    }

    .app_landing_banner .banner_content .title_text {
        font-size: 48px;
    }

    .chat_banner .banner_content .title_text {
        font-size: 42px;
    }

    .digital_marketing_banner .banner_content .title_text {
        font-size: 42px;
    }

    .hr_management_banner .banner_content .title_text span {
        font-size: 42px;
    }

    .social_icon>ul>li {
        margin-right: 5px;
    }

    .team_grid .member_name>a {
        font-size: 18px;
    }

    .team_grid .member_image {
        margin-bottom: 12px;
    }

    .team_grid .member_title {
        font-size: 13px;
        display: block;
    }

    .promotion_section .btns_group>ul>li {
        margin-right: 20px;
    }
}



@media screen and (max-width: 1024px) {
    body {
        font-size: 15px;
    }

    .sec_ptb_120 {
        padding: 100px 0;
    }

    .sec_ptb_160 {
        padding: 100px 0;
    }

    .sec_ptb_345 {
        padding: 220px 0;
    }

    .mb-80 {
        margin-bottom: 60px;
    }

    .mb-50 {
        margin-bottom: 40px;
    }

    .mr--30 {
        margin-right: 0;
    }

    .ml--30 {
        margin-left: 0;
    }

    .section_title.mb-50 {
        margin-bottom: 30px;
    }

    .section_title .title_text {
        font-size: 36px;
    }

    @-webkit-keyframes ripple {
        0% {
            -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 10px rgba(255, 255, 255, 0.2), 0 0 0 30px rgba(255, 255, 255, 0.2);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 10px rgba(255, 255, 255, 0.2), 0 0 0 30px rgba(255, 255, 255, 0.2);
        }

        100% {
            -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2), 0 0 0 30px rgba(255, 255, 255, 0.2), 0 0 0 40px transparent;
            box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2), 0 0 0 30px rgba(255, 255, 255, 0.2), 0 0 0 40px transparent;
        }
    }

    @keyframes ripple {
        0% {
            -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 10px rgba(255, 255, 255, 0.2), 0 0 0 30px rgba(255, 255, 255, 0.2);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 10px rgba(255, 255, 255, 0.2), 0 0 0 30px rgba(255, 255, 255, 0.2);
        }

        100% {
            -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2), 0 0 0 30px rgba(255, 255, 255, 0.2), 0 0 0 40px transparent;
            box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2), 0 0 0 30px rgba(255, 255, 255, 0.2), 0 0 0 40px transparent;
        }
    }

    .footer_section .social_links.social_rounded>ul>li {
        width: 35px;
        height: 35px;
        margin-right: 5px;
    }

    .footer_section .social_links.social_rounded>ul>li>a {
        line-height: 37px;
    }

    .breadcrumb_section .spahe_1,
    .breadcrumb_section .spahe_2 {
        max-width: 290px;
    }

    .breadcrumb_section .spahe_1 {
        top: -50px;
        right: -50px;
    }

    .breadcrumb_section .spahe_2 {
        left: -50px;
        bottom: -50px;
    }

    .breadcrumb_section {
        min-height: 450px;
    }

    .breadcrumb_section .breadcrumb_content p {
        font-size: 16px;
    }

    .breadcrumb_section .breadcrumb_content .page_title {
        font-size: 48px;
    }

    .sidebar_section .widget {
        margin-bottom: 40px;
    }

    .sidebar_section .widget_title {
        font-size: 26px;
    }

    .sidebar_section.pl-30 {
        padding: 0;
    }

    .service_boxed_1 {
        padding: 50px 30px;
    }

    .team_grid .member_name {
        font-size: 20px;
    }

    .section_title.increase_size .title_text {
        font-size: 36px;
    }

    .service_section .tabs_nav>ul>li>a strong {
        font-size: 16px;
    }

    .service_section .tabs_nav>ul>li>a {
        padding: 30px;
    }

    .service_section .tabs_nav>ul>li>a span {
        width: 60px;
        height: 60px;
        font-size: 26px;
        line-height: 60px;
        margin-bottom: 20px;
    }

    .service_section .tab-content .service_image_2 {
        max-width: 770px;
        margin: 0 auto;
    }

    .service_section .tab-content .service_image_2 .image_2 {
        max-width: 300px;
    }

    .service_section .tab-content .service_image_2 .image_1 {
        width: 100%;
        max-width: auto;
        margin-bottom: -65px;
    }

    .introduction_section .video_wrap {
        max-width: 730px;
        margin: 0 auto;
    }

    .introduction_section .video_wrap .dot_image {
        top: -20px;
        right: 75px;
        max-width: 100px;
    }

    .introduction_section .video_wrap .man_2 {
        left: -65px;
        bottom: 50px;
        max-width: 135px;
    }

    .introduction_section .video_wrap .man_1 {
        right: -40px;
        bottom: 70px;
        max-width: 100px;
    }

    .introduction_section .video_wrap .leaf_image {
        left: 35px;
        bottom: 45px;
        max-width: 90px;
    }

    .video_wrap .banner_image .popup_video {
        width: 60px;
        height: 60px;
        font-size: 50px;
        line-height: 62px;
    }

    .feature_section .feature_image_2 {
        max-width: 330px;
        margin-left: -100px;
    }

    .feature_section .feature_image_2 .image_2 {
        max-width: 250px;
        top: 90px;
        left: -100px;
    }

    .feature_section .feature_image_2 .image_3 {
        max-width: 200px;
        right: -65px;
        bottom: 85px;
    }

    .register_section .signin_image {
        width: 350px;
        margin-right: 50px;
    }

    .process_section .process_item:nth-child(2n) .item_content::before {
        left: -68px;
    }

    .process_section .process_item:nth-child(2n) .item_content::after {
        left: -62px;
    }

    .pricing_plan_1 {
        padding: 50px 45px;
    }

    .pricing_plan_1 .info_list>ul>li {
        font-size: 15px;
        margin-bottom: 20px;
    }

    .pricing_plan_3 {
        padding: 50px;
    }

    .pricing_plan_3.special_item {
        padding: 70px 50px;
    }

    .pricing_plan_3 p {
        margin-bottom: 30px;
    }

    .casestudy_fullimage .item_content .item_title>a {
        font-size: 18px;
    }

    .blog_small .item_title>a {
        font-size: 16px;
    }

    .blog_small .post_date {
        font-size: 13px;
    }

    .blog_grid .item_image {
        margin-bottom: 30px;
    }

    .blog_grid .item_title>a {
        font-size: 26px;
    }

    .blog_section .pagination_nav>ul {
        margin: 0 auto;
    }

    .blog_grid.decrease_size .item_title>a {
        font-size: 22px;
    }

    .details_section .blog_grid .item_title>a {
        font-size: 17px;
    }

    .details_section .blog_grid .post_date {
        top: 10px;
        left: 10px;
        min-width: 55px;
        min-height: 55px;
        padding: 10px 8px;
    }

    .details_section .blog_grid .post_date strong {
        font-size: 19px;
        margin-bottom: 1px;
    }

    .details_section .blog_grid .post_date span {
        font-size: 12px;
    }

    .details_section .blog_grid .item_image {
        margin-bottom: 15px;
    }

    .details_section .blog_grid .item_title {
        margin-bottom: 10px;
    }

    .agency_banner .bg_1 {
        width: 640px;
        right: -170px;
    }

    .agency_banner .bg_2 {
        width: 750px;
        right: -300px;
    }

    .agency_banner .cloud_3 {
        top: 300px;
        left: -85px;
    }

    .testimonial_section .shape_2 {
        max-width: 360px;
    }

    .partner_section .shape_1 {
        max-width: 220px;
    }

    .chat_banner {
        min-height: auto;
        padding-top: 150px;
        padding-bottom: 60px;
    }

    .chat_banner .bg_1 {
        right: -110px;
    }

    .chat_banner .bg_2 {
        right: -115px;
    }

    .service_section .shape_3 {
        bottom: -170px;
        max-width: 630px;
    }

    .introduction_section .video_wrap .leaf_image_1,
    .introduction_section .video_wrap .leaf_image_2 {
        bottom: 30px;
        max-width: 90px;
    }

    .introduction_section .video_wrap {
        padding-bottom: 50px;
    }

    .digital_agency_banner {
        min-height: 700px;
    }

    .digital_agency_banner .banner_image {
        max-width: 440px;
    }

    .digital_agency_banner .shape_1 {
        top: -98px;
        right: -145px;
        max-width: 600px;
    }

    .digital_agency_banner .shape_2 {
        top: -115px;
        right: -190px;
        max-width: 660px;
    }

    .digital_marketing_banner .banner_image {
        max-width: 430px;
    }

    .digital_marketing_banner .apps_wrap>ul {
        width: 260px;
        height: 250px;
    }

    .digital_marketing_banner .apps_wrap.wrap_left {
        left: 15px;
    }

    .digital_marketing_banner .apps_wrap.wrap_right {
        right: 15px;
    }

    .feature_section .feature_image_7 {
        max-width: 543px;
        margin-left: 0;
    }

    .hr_management_banner {
        min-height: 700px;
    }

    .hr_management_banner .banner_image {
        top: 0;
        right: -385px;
        -webkit-transform: scale(0.64);
        -ms-transform: scale(0.64);
        transform: scale(0.64);
    }

    .hr_management_banner .shape_1 {
        max-width: 310px;
    }

    .hr_management_banner .shape_2 {
        max-width: 460px;
    }

    .hr_management_banner .shape_3 {
        max-width: 600px;
    }

    .payment_banner .shape_3 {
        max-width: 90px;
    }

    .payment_banner .banner_content {
        max-width: 430px;
    }

    .banner_section .banner_content .title_text {
        margin-bottom: 20px;
    }

    .banner_section .banner_content p {
        margin-bottom: 30px;
    }

    .payment_banner .shape_1 {
        left: -130px;
        bottom: -55px;
        max-width: 430px;
    }

    .payment_banner {
        min-height: auto;
        padding-bottom: 30px;
    }

    .payment_banner .banner_image {
        width: 330px;
        margin-left: -80px;
    }

    .payment_banner .banner_image .card_image {
        max-width: 330px;
        left: -130px;
    }

    .feature_section .feature_image_9 {
        float: right;
        max-width: 450px;
        margin-right: -70px;
    }

    .feature_section .feature_image_9 .child_image {
        max-width: 240px;
        top: 120px;
        right: -60px;
    }

    .feature_section .feature_image_9 .dot_image {
        max-width: 110px;
        left: 10px;
        top: 125px;
    }

    .feature_section .feature_image_2 {
        margin-left: -75px;
    }

    .feature_section .shape_10 {
        left: -100px;
        max-width: 400px;
    }

    .feature_section .shape_9 {
        right: -140px;
        max-width: 400px;
    }

    .feature_section .shape_11 {
        bottom: -55px;
        max-width: 430px;
    }

    .testimonial_section .shape_3 {
        top: -110px;
        right: -140px;
        max-width: 430px;
    }

    .project_management_banner .banner_image {
        width: 760px;
        margin: 80px auto -225px;
    }

    .saas_banner {
        min-height: auto;
        padding-top: 200px;
        margin-bottom: 164px;
    }

    .saas_banner .circle_1 {
        left: -130px;
        bottom: -50px;
        max-width: 300px;
    }

    .saas_banner .circle_3 {
        right: 15%;
        bottom: 140px;
        max-width: 170px;
    }

    .saas_banner .circle_4 {
        right: 3%;
        top: 190px;
        max-width: 50px;
    }

    .feature_section .section_title .tabs_nav>ul>li>a {
        padding: 12px 25px;
    }

    .software_banner .banner_image {
        width: auto;
        float: none;
        max-width: 470px;
        margin: 0 auto 40px;
    }

    .software_banner .banner_content {
        margin: 0 auto;
        max-width: 430px;
        text-align: center;
    }

    .software_banner .banner_content .btns_group>ul {
        margin: 0 auto;
    }

    .software_banner .bg_shape_3 {
        right: 0;
        background-size: 90%;
        background-position: center top;
    }

    .software_banner .shape_2 {
        bottom: 0;
        max-width: 430px;
    }

    .feature_section .feature_image_3 .image_2 {
        max-width: 300px;
        top: 60px;
        left: -120px;
    }

    .feature_section .feature_image_3 .image_3 {
        max-width: 300px;
        top: 150px;
        left: -100px;
    }

    .shop_details .item_content {
        padding-left: 0;
    }

    .banner_section .banner_content .title_text {
        font-size: 38px;
    }

    .feature_section .section_title .title_text {
        font-size: 32px;
    }

    .digital_agency_banner {
        min-height: 600px;
    }

    .banner_section .banner_content .popup_video small {
        font-size: 14px;
    }

    .section_title .title_text {
        font-size: 34px;
    }

    .feature_section .btns_group>ul>li {
        margin-right: 15px;
    }

    .software_banner .banner_content {
        margin: 0;
        max-width: 430px;
        text-align: left;
    }

    .software_banner .banner_content .btns_group>ul {
        margin: 0;
    }

    .chat_banner .banner_content .title_text {
        font-size: 46px;
    }

    .digital_marketing_banner .banner_content .title_text {
        font-size: 42px;
    }

    .payment_banner .banner_content .title_text {
        font-size: 42px;
        letter-spacing: 0;
    }
}

@media screen and (max-width: 991px) {
    .order-last {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }

    .mr--30 {
        margin-right: 0;
    }

    .ml--30 {
        margin-left: 0;
    }

    @-webkit-keyframes ripple_3 {
        0% {
            -webkit-box-shadow: 0 0 0 0 rgba(94, 44, 237, 0.2), 0 0 0 30px rgba(94, 44, 237, 0.2), 0 0 0 50px rgba(94, 44, 237, 0.2);
            box-shadow: 0 0 0 0 rgba(94, 44, 237, 0.2), 0 0 0 30px rgba(94, 44, 237, 0.2), 0 0 0 50px rgba(94, 44, 237, 0.2);
        }

        100% {
            -webkit-box-shadow: 0 0 0 30px rgba(94, 44, 237, 0.2), 0 0 0 50px rgba(94, 44, 237, 0.2), 0 0 0 70px transparent;
            box-shadow: 0 0 0 30px rgba(94, 44, 237, 0.2), 0 0 0 50px rgba(94, 44, 237, 0.2), 0 0 0 70px transparent;
        }
    }

    @keyframes ripple_3 {
        0% {
            -webkit-box-shadow: 0 0 0 0 rgba(94, 44, 237, 0.2), 0 0 0 30px rgba(94, 44, 237, 0.2), 0 0 0 50px rgba(94, 44, 237, 0.2);
            box-shadow: 0 0 0 0 rgba(94, 44, 237, 0.2), 0 0 0 30px rgba(94, 44, 237, 0.2), 0 0 0 50px rgba(94, 44, 237, 0.2);
        }

        100% {
            -webkit-box-shadow: 0 0 0 30px rgba(94, 44, 237, 0.2), 0 0 0 50px rgba(94, 44, 237, 0.2), 0 0 0 70px transparent;
            box-shadow: 0 0 0 30px rgba(94, 44, 237, 0.2), 0 0 0 50px rgba(94, 44, 237, 0.2), 0 0 0 70px transparent;
        }
    }

    .btn {
        padding: 17px 35px;
    }

    .btn.btn_border {
        padding: 16px 35px;
    }

    .header_section .brand_logo>.brand_link {
        max-width: 125px;
    }

    .header_section .btn,
    .header_section .main_menu,
    .header_section .btn.btn_border.border_white {
        display: none;
    }

    .header_section {
        min-height: 100px;
    }

    .header_section .menu_btn {
        display: inline-block;
    }

    .footer_section .useful_links>ul>li {
        margin-bottom: 8px;
    }

    .footer_section .widget_title {
        font-size: 20px;
        margin-bottom: 30px;
    }

    .footer_section .about_content {
        padding: 0;
        max-width: 460px;
    }

    .footer_section .useful_links,
    .footer_section .social_links {
        margin-top: 40px;
    }

    .footer_section .widget_area {
        padding: 80px 0;
    }

    .footer_section .deco_image_1 {
        bottom: -81px;
        max-width: 150px;
    }

    .footer_section .deco_image_2 {
        bottom: -81px;
        max-width: 100px;
    }

    .footer_section.sec_ptb_120.pb-0 {
        padding: 0;
    }

    .footer_section .sms_comment {
        max-width: 180px;
        display: inline-block;
    }

    .footer_section .footer_bottom .social_links {
        margin: 0;
    }

    .footer_section .cloud_image {
        max-width: 70px;
    }

    .footer_section .cloud_image.cloud_1 {
        top: 60px;
        left: -15px;
    }

    .footer_section .cloud_image.cloud_3 {
        top: 140px;
        right: -15px;
    }

    .footer_section .cloud_image.cloud_2 {
        bottom: 100px;
    }

    .footer_section .social_icon_rounded {
        margin-top: 40px;
    }

    .error_section .spahe_1,
    .error_section .spahe_2 {
        max-width: 350px;
    }

    .error_section .spahe_1 {
        top: -50px;
        right: -50px;
    }

    .error_section .spahe_2 {
        left: -50px;
        bottom: -50px;
    }

    .error_section .error_content .page_title {
        font-size: 270px;
    }

    .error_section .error_content .title_text {
        font-size: 42px;
    }

    .error_section .error_content p {
        display: table;
        max-width: 720px;
        margin: 0 auto 50px;
    }

    .feature_section .feature_image {
        width: 100%;
        margin: 0;
        float: none;
        margin-bottom: 40px;
    }

    .feature_section .section_title {
        margin: 0;
        text-align: center;
    }

    .team_grid .member_name {
        font-size: 18px;
        margin-bottom: 1px;
    }

    .team_grid .member_title {
        font-size: 13px;
    }

    .team_grid .member_social_links>ul>li>a {
        font-size: 14px;
    }

    .service_section .service_image {
        width: auto;
        float: none;
        max-width: 570px;
        margin: 50px auto 40px;
    }

    .service_primary {
        padding: 0 30px;
        text-align: center;
    }

    .testimonial_section .testimonial_carousel_1 {
        min-height: 550px;
        margin: 0 auto 110px;
    }

    .testimonial_section .testimonial_carousel_1 .owl-nav {
        left: 45%;
        bottom: -75px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        -webkit-transform: translateX(-45%);
        -ms-transform: translateX(-45%);
        transform: translateX(-45%);
    }

    .newsletter_section .newsletter_boxed {
        margin: 0 -15px;
        border-radius: 0;
        -webkit-transform: unset !important;
        -ms-transform: unset !important;
        transform: unset !important;
    }

    .feature_section .feature_image_2 {
        max-width: 330px;
        margin: -60px auto 60px;
    }

    .feature_section .feature_image_2 .phone_image {
        margin: 0;
    }

    .feature_section .feature_image_3 {
        width: auto;
        float: none;
        max-width: 370px;
        margin: -55px auto 40px;
    }

    .feature_section .feature_image_3 .image_1 {
        max-width: 330px;
        top: 150px;
        left: -135px;
    }

    .owl-theme.arrow_top_right .owl-nav {
        top: -80px;
        right: 15px;
    }

    .register_section .signin_image {
        width: auto;
        float: none;
        max-width: 470px;
        margin: 0 auto 60px;
    }

    .register_section.half_bg_white::before {
        display: none;
    }

    .register_section .section_title {
        text-align: center;
    }

    .process_section .process_item .order-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }

    .process_section .process_item .item_title {
        font-size: 30px;
    }

    .process_section .process_item p {
        font-size: 13px;
    }

    .pricing_plan_3 .price_text {
        font-size: 38px;
    }

    .pricing_plan_3 .title_text {
        font-size: 16px;
        margin-bottom: 25px;
    }

    .pricing_plan_3 p {
        font-size: 15px;
    }

    .pricing_tab {
        font-size: 16px;
        margin-bottom: 30px;
    }

    .newsletter_section .section_title {
        text-align: center;
        margin-bottom: 40px;
    }

    .btn.btn_border.float-right {
        display: table;
        margin: 30px auto 0;
        float: none !important;
    }

    .job_details_info {
        margin-bottom: 60px;
    }

    .apply_section .apply_form .section_title {
        max-width: 570px;
        text-align: center;
        margin: 0 auto 40px;
    }

    .apply_section .apply_form .terms-conditions {
        text-align: center;
        margin: 0 auto 30px;
    }

    .apply_section .apply_form .btn_wrap {
        text-align: center;
    }

    .faq_section .tab-content {
        padding-left: 0;
    }

    .faq_section .tabs_nav {
        margin: 0;
        margin-bottom: 60px;
    }

    .contact_section .contact_form {
        margin-top: 60px;
    }

    .details_section .item_image.mr--30 {
        margin: 0;
    }

    .details_section .chield_item {
        text-align: center;
    }

    .details_section .chield_item .item_image {
        margin-bottom: 40px;
    }

    .details_section .chield_item .item_content .info_list {
        display: table;
        margin: 0 auto;
        text-align: left;
    }

    .agency_banner {
        min-height: auto;
        padding-top: 150px;
        padding-bottom: 100px;
    }

    .agency_banner .banner_image {
        width: auto;
        max-width: 500px;
        margin: 0 auto 40px;
    }

    .agency_banner .bg_2 {
        width: 820px;
    }

    .agency_banner .bg_1 {
        width: 700px;
    }

    .agency_banner .clouds_wrap {
        width: 100%;
    }

    .agency_banner .cloud_2 {
        left: 150px;
    }

    .agency_banner .cloud_3 {
        top: 300px;
        left: 30px;
    }

    .agency_banner .banner_content {
        text-align: center;
    }

    .agency_banner .banner_content .btns_group>ul {
        margin: 0 auto;
    }

    .service_section .dot_image_1 {
        top: -150px;
        left: -75px;
        max-width: 130px;
    }

    .home_agency .service_primary_2 {
        padding: 0;
        max-width: 290px;
        text-align: center;
        margin: 50px auto 0;
    }

    .feature_section .shape_1 {
        top: 0;
        left: -225px;
        max-width: 700px;
    }

    .feature_section .dot_image_1 {
        top: 130px;
        right: -70px;
        max-width: 100px;
    }

    .feature_section .shape_2 {
        top: 135px;
        max-width: 200px;
    }

    .feature_section.bg_flow {
        min-height: auto;
        background-size: auto 100%;
    }

    .testimonial_section .shape_2 {
        max-width: 350px;
    }

    .testimonial_section .shape_1 {
        top: 150px;
        left: -70px;
        max-width: 100px;
    }

    .testimonial_section .testimonial_carousel_1.secondary_style {
        margin: 0;
        min-height: auto;
    }

    .partner_section .shape_1 {
        max-width: 190px;
    }

    .app_landing_banner .banner_content {
        text-align: left;
    }

    .app_landing_banner .banner_content .btns_group>ul {
        margin: 0;
    }

    .app_landing_banner .banner_image .phone_1 {
        max-width: 300px;
    }

    .app_landing_banner .banner_image .phone_2 {
        max-width: 260px;
    }

    .app_landing_banner {
        padding-bottom: 60px;
    }

    .app_landing_banner .banner_image {
        margin-bottom: 0;
    }

    .feature_section .feature_image_4 {
        max-width: 530px;
        margin: 0 auto 60px;
    }

    .promotion_section .item_image_1 {
        width: 370px;
        margin: 0 auto 100px;
    }

    .promotion_section .item_image_1 .image_1,
    .promotion_section .item_image_1 .image_2 {
        max-width: 49%;
    }

    .promotion_section .section_title {
        text-align: center;
    }

    .promotion_section .btns_group>ul {
        margin: 0 auto;
    }

    .promotion_section .dot_image_1 {
        right: -60px;
        max-width: 100px;
    }

    .showcase_section .mobile_frame {
        width: 310px;
        height: 585px;
    }

    .service_section .shape_3 {
        bottom: -80px;
        max-width: 520px;
    }

    .feature_section .feature_image_5 {
        max-width: 400px;
        margin: 0 auto 50px;
    }

    .feature_section .btns_group>ul {
        float: none;
        margin: 0 auto;
    }

    .feature_section .btns_group>ul>li {
        margin-right: 15px;
    }

    .feature_section .feature_image_6 {
        float: none;
        width: 100%;
        margin: 0 auto 50px;
    }

    .feature_section .shape_7 {
        max-width: 180px;
    }

    .feature_section .shape_8 {
        left: 110px;
        max-width: 160px;
    }

    .feature_section .feature_image_6 .dot_image_1 {
        top: 80px;
        right: 60px;
        max-width: 80px;
    }

    .service_section .service_image {
        width: 100%;
        margin: 30px auto 0;
    }

    .home_digital_marketing .service_primary {
        padding: 0 25px;
    }

    .feature_section .feature_image_7 {
        float: none;
        max-width: 470px;
        margin: 0 auto 40px;
    }

    .seo_section .form_wrap {
        max-width: 600px;
    }

    .seo_section .rocket_image {
        max-width: 180px;
    }

    .seo_section .shape_3 {
        right: 5%;
        max-width: 60px;
    }

    .seo_section .shape_2 {
        max-width: 60px;
    }

    .seo_section .shape_1 {
        max-width: 120px;
    }

    .seo_section .glob_image_1 {
        top: -45px;
        max-width: 350px;
    }

    .feature_section .feature_image_8 {
        float: none;
        max-width: 530px;
        margin: 0 auto 40px;
    }

    .newsletter_section .newsletter_boxed .shape_2 {
        right: 40px;
        bottom: 55%;
        max-width: 70px;
    }

    .newsletter_section .newsletter_boxed .shape_1 {
        bottom: 40px;
        max-width: 130px;
    }

    .casestudy_grid .item_title>a {
        font-size: 20px;
    }

    .casestudy_grid .item_image {
        margin-bottom: 18px;
    }

    .home_chat .menu_btn {
        color: #ff6b00;
    }

    .intregation_section .apps_wrap>ul>li {
        width: 40px;
        height: 40px;
    }

    .intregation_section .apps_wrap>ul>li>a>img {
        height: 18px;
    }

    .intregation_section .apps_wrap>ul {
        width: 200px;
        height: 185px;
    }

    .intregation_section .intregation_image_1 {
        max-width: 430px;
    }

    .intregation_section .intregation_image_1 .phone_image {
        left: -25px;
        bottom: -2px;
        max-width: 85px;
    }

    .intregation_section .intregation_image_1 .watch_image {
        bottom: 0;
        right: -10px;
        max-width: 70px;
    }

    .payment_banner .banner_image {
        float: none;
        width: 260px;
        margin: 0 auto 30px;
    }

    .payment_banner .banner_image .card_image {
        max-width: 240px;
        left: -90px;
    }

    .payment_banner .banner_image .apps_wrap {
        top: -85px;
    }

    .payment_banner .banner_image .apps_wrap>ul {
        width: 415px;
        height: 350px;
    }

    .payment_banner .banner_image .apps_wrap>ul>li:nth-child(1) {
        max-width: 80px;
        top: 130px;
    }

    .payment_banner .banner_image .apps_wrap>ul>li:nth-child(2) {
        max-width: 90px;
        left: 90px;
    }

    .payment_banner .banner_image .apps_wrap>ul>li:nth-child(3) {
        max-width: 85px;
        right: 105px;
    }

    .payment_banner .banner_image .apps_wrap>ul>li:nth-child(4) {
        max-width: 85px;
        top: 135px;
    }

    .payment_banner .banner_image .apps_wrap>ul>li:nth-child(5) {
        max-width: 140px;
    }

    .payment_banner .banner_content {
        padding: 0;
        margin: 0 auto;
        text-align: center;
    }

    .payment_banner {
        min-height: auto;
        padding-top: 230px;
        padding-bottom: 190px;
    }

    .payment_banner .banner_content .btns_group>ul {
        margin: 0 auto;
    }

    .payment_banner .banner_content .btns_group>ul>li {
        margin-right: 15px;
    }

    .banner_section .banner_content .btns_group>ul>li .btn_text {
        font-size: 16px;
    }

    .feature_section .feature_image_9 {
        float: none;
        max-width: 430px;
        margin: 0 auto 50px;
    }

    .feature_section .feature_image_9 .chart_image {
        margin: 0 auto;
    }

    .feature_section .feature_image_2 {
        max-width: 300px;
        margin: -60px auto 60px;
    }

    .feature_section .feature_image_2 .image_2 {
        max-width: 200px;
        top: 80px;
        left: -70px;
    }

    .feature_section .feature_image_2 .image_3 {
        max-width: 180px;
        right: -55px;
        bottom: 75px;
    }

    .project_management_banner {
        padding-top: 160px;
    }

    .banner_section .banner_image .popup_video {
        width: 70px;
        height: 70px;
        font-size: 54px;
        line-height: 75px;
    }

    .feature_section .feature_image_10 {
        width: auto;
        float: none;
        max-width: 370px;
        margin: 0 auto 40px;
    }

    .feature_section .feature_image_10 .big_image {
        margin-bottom: -10px;
    }

    .feature_section .child_feature_2 {
        text-align: left;
        margin-bottom: 40px;
    }

    .feature_section .feature_image_10 .child_image_1,
    .feature_section .feature_image_10 .child_image_2,
    .feature_section .feature_image_10 .child_image_3 {
        max-width: 300px;
        right: -75px;
    }

    .feature_section .feature_image_10 .child_image_1 {
        top: 110px;
    }

    .feature_section .feature_image_10 .child_image_2 {
        top: 190px;
    }

    .feature_section .feature_image_10 .child_image_3 {
        max-width: 330px;
        top: 270px;
    }

    .service_section .tab-content .service_image_3 {
        width: 100%;
        float: none;
        margin: 0;
    }

    .service_section .dot_image_2 {
        top: 90px;
        right: 8px;
    }

    .service_section .tabs_nav_2>ul>li a {
        padding: 30px;
    }

    .feature_section .feature_image_11 {
        max-width: 530px;
        margin: 0 auto 40px;
    }

    .saas_banner .banner_image {
        width: 100%;
    }

    .saas_banner .banner_image .phone_image {
        right: 12px;
        bottom: -12px;
        max-width: 150px;
    }

    .feature_section .feature_image_12 {
        width: auto;
        float: none;
        padding: 0;
        max-width: 570px;
        margin: 0 auto 120px;
    }

    .feature_section .child_feature_2 .item_content .item_title {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .feature_section .child_feature_2 .item_content p {
        font-size: 14px;
    }

    .feature_section .app_icons_group>ul {
        margin: 0 auto;
    }

    .feature_section .feature_image_13 {
        width: auto;
        float: none;
        max-width: 530px;
        margin: 0 auto 70px;
    }

    .h_map ul li:nth-child(1) {
        top: 60px;
        left: 45px;
    }

    .h_map ul li:nth-child(2) {
        top: 195px;
        left: 110px;
    }

    .h_map ul li:nth-child(3) {
        left: 50%;
        top: 115px;
    }

    .h_map ul li:nth-child(5) {
        left: 88%;
        top: 80px;
    }

    .h_map ul li:nth-child(4) {
        top: 270px;
        left: 73%;
    }

    .h_map ul li:nth-child(6) {
        top: 70%;
        left: 220px;
    }

    .h_map ul li:nth-child(7) {
        top: 60%;
        left: 52%;
    }

    .h_map ul li:nth-child(8) {
        top: 79%;
        left: 93%;
    }

    .feature_section .feature_image_14 {
        width: auto;
        float: none;
        max-width: 370px;
        margin: 0 auto 60px;
    }

    .feature_section .feature_image_14 .child_image {
        max-width: 290px;
        bottom: 20%;
        right: -25%;
    }

    .feature_section .section_title .tabs_nav>ul {
        margin: 0 auto;
    }

    .shop_list .item_content {
        padding-top: 30px;
        padding-left: 30px;
    }

    .cart_section .cart_info_sidebar {
        margin-top: 40px;
    }

    .checkout_section .checkout_sidebar {
        margin-top: 40px;
        margin-left: 0;
    }

    .casestudy_section .container-fluid .element_grid.column_4 .casestudy_grid .item_title>a {
        font-size: 18px;
    }

    .gallery_section .element_grid.column_2 .element-item,
    .casestudy_section .element_grid.column_2 .element-item,
    .portfolio_section .element_grid.column_2 .element-item {
        width: 33.333%;
    }

    .details_section .item_content {
        margin-top: 30px;
    }

    .blog_small .item_content {
        margin: 0;
    }

    .sidebar_section.pl-30 {
        padding: 0;
    }

    .sidebar_section {
        margin-top: 60px;
    }

    .home_chat:not(.home_digital_agency) .footer_section .useful_links,
    .home_chat:not(.home_digital_agency) .footer_section .social_links {
        margin-top: 0;
    }

    .feature_section .section_title .sub_title.c_slide_in_wrap1 {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .home_saas .feature_section .section_title.decrease_size .c_slide_in_wrap1 {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .software_banner .banner_content {
        margin: 0 auto;
        max-width: 430px;
        text-align: center;
    }

    .software_banner .banner_content .btns_group>ul {
        margin: 0 auto;
    }

    .promotion_section .section_title .sub_title {
        text-align: center;
    }

    .promotion_section .section_title .sub_title span {
        margin: auto;
        margin-bottom: 5px;
    }

    .header_section .menu_btn {
        margin: 6px 0;
    }

    .sticky_header.stuck .brand_logo>.brand_link {
        max-width: 125px;
    }

    .sticky_header.stuck {
        min-height: 80px;
    }

    .newsletter_section.text-white .form_item input.text-left {
        text-align: center !important;
    }

    .payment_banner .banner_content .btns_group>ul,
    .software_banner .banner_content .btns_group>ul {
        margin: 0 auto;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .h_map_section .section_title .title_text {
        font-size: 33px;
    }

    .checkout_section .report_info .card-body .btn.bg_default_blue {
        width: 100%;
        display: block;
        margin-top: 15px;
    }
}

@media screen and (max-width: 940px) {
    .pricing_plan_1 {
        padding: 40px 30px;
    }

    .pricing_plan_1 .pricing_header.mb-50 {
        margin-bottom: 30px;
    }

    .pricing_plan_1 .pricing_header .price_text strong {
        font-size: 42px;
    }

    .pricing_plan_1 .item_icon {
        width: 140px;
        height: 100px;
        margin: 0 auto 30px;
    }

    .pricing_plan_1 .item_icon img {
        height: 100px;
    }

    .pricing_plan_1 .info_list {
        margin-bottom: 30px;
    }

    .pricing_plan_1 .info_list>ul>li {
        font-size: 14px;
        margin-bottom: 18px;
    }

    .pricing_plan_2 {
        padding: 40px 30px;
    }

    .pricing_plan_2 .pricing_header {
        margin-bottom: 30px;
        padding-bottom: 30px;
    }

    .pricing_plan_2 .pricing_header .title_text {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .pricing_plan_2 .pricing_header .price_text strong {
        font-size: 42px;
    }

    .pricing_plan_2 .info_list {
        margin-bottom: 30px;
    }

    .pricing_plan_2 .info_list>ul li {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .pricing_plan_2 .item_label {
        right: 20px;
        max-width: 25px;
    }

    .pricing_plan_3 {
        padding: 40px 30px;
    }

    .pricing_plan_3.special_item {
        padding: 60px 30px;
    }

    .pricing_plan_3 p {
        font-size: 14px;
    }

    .gallery_section .element_grid.column_2 .element-item,
    .casestudy_section .element_grid.column_2 .element-item,
    .portfolio_section .element_grid.column_2 .element-item {
        width: 50%;
    }

    .app_landing_banner .banner_content .title_text {
        font-size: 48px;
    }

    .testimonial_section .section_title {
        text-align: center;
    }

    .section_title .title_text {
        font-size: 30px;
    }

    .testimonial_section .section_title .c_slide_in_wrap1 {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

@media screen and (max-width: 880px) {
    .section_title .title_text {
        font-size: 32px;
    }

    .section_title p {
        font-size: 14px;
    }

    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }

    .btn {
        padding: 17px 35px;
    }

    .btn.btn_border {
        padding: 16px 35px;
    }

    .section_title .title_text.mb-30 {
        margin-bottom: 20px;
    }

    .section_title.c_slide_in .title_text {
        line-height: 1.1;
    }

    .breadcrumb_section .breadcrumb_content p {
        font-size: 14px;
    }

    .breadcrumb_section {
        min-height: 180px;
    }

    .service_boxed_1 .item_icon {
        width: 70px;
        height: 70px;
        font-size: 40px;
        line-height: 70px;
    }

    .service_boxed_1 .icon_wrap {
        margin-bottom: 20px;
    }

    .service_boxed_1 .item_title {
        margin-bottom: 15px;
    }

    .service_boxed_1 .item_title>a {
        font-size: 20px;
    }

    .service_boxed_1 p {
        font-size: 13px;
        margin-bottom: 20px;
    }

    .service_boxed_1 {
        padding: 40px 30px;
    }

    .service_boxed_1 .details_btn {
        font-size: 42px;
    }

    .partner_section .partner_logo {
        height: 40px;
        margin-top: 30px;
    }

    .partner_section .partner_logo>a {
        max-width: 105px;
    }

    .service_primary {
        padding: 0;
        max-width: 230px;
        text-align: center;
        margin: 50px auto 0;
    }

    .service_section .tabs_nav>ul {
        width: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .service_section .tabs_nav>ul>li {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333%;
        flex: 0 0 33.333%;
        max-width: 33.333%;
    }

    .service_section .tabs_nav>ul>li>a {
        padding: 40px 30px;
    }

    .service_section .tab-content .service_image_2 .image_1 {
        max-width: 670px;
        margin: 0 auto -54px;
    }

    .service_section .tab-content .service_image_2 .image_2 {
        top: 80px;
        max-width: 250px;
    }

    .testimonial_section .testimonial_carousel_2 .testimonial_item {
        max-width: 470px;
        margin: 0 auto;
    }

    .testimonial_section .bg_area {
        height: 390px;
    }

    .register_section .signup_wrap .info_wrap,
    .register_section .signup_wrap .signup_form {
        padding: 0 45px;
    }

    .register_section .signup_wrap {
        padding: 50px 0;
    }

    .register_section .signup_wrap .info_wrap h3 {
        font-size: 26px;
        margin-bottom: 30px;
    }

    .register_section .signup_wrap .info_wrap h4 {
        font-size: 20px;
        margin-bottom: 40px;
    }

    .process_section .process_item:nth-child(2n) .item_content::after {
        left: -58px;
    }

    .process_section .process_item:nth-child(2n) .item_content::before {
        left: -64px;
    }

    .process_section .process_item .item_title {
        font-size: 28px;
    }

    .process_section .process_item .serial_number {
        margin-bottom: 35px;
    }

    .job_item .item_content {
        padding-top: 0;
    }

    .job_item .item_title>a {
        font-size: 18px;
    }

    .job_item p {
        font-size: 13px;
    }

    .blog_grid .item_title>a {
        font-size: 22px;
    }

    .blog_grid .details_btn {
        font-size: 16px;
    }

    .blog_quote p a {
        font-size: 16px;
    }

    .service_section .dot_image_1 {
        top: -130px;
        left: -60px;
        max-width: 100px;
    }

    .feature_section .shape_2 {
        top: 40px;
        max-width: 100px;
    }

    .introduction_section .video_wrap {
        padding-bottom: 45px;
    }

    .app_landing_banner .banner_content .title_text {
        font-size: 42px;
    }

    .app_landing_banner .banner_content {
        margin-top: -100px;
    }

    .service_boxed_3 {
        padding: 35px 30px;
    }

    .feature_section .feature_image_4 {
        max-width: 420px;
    }

    .feature_section .feature_image_4 .phone_image {
        max-width: 370px;
        margin-left: -90px;
    }

    .feature_section .feature_image_4 .chat_image {
        max-width: 300px;
        top: 50px;
    }

    .feature_section .shape_6 {
        max-width: 250px;
    }

    .feature_section .dot_1 {
        top: 180px;
        right: 100px;
    }

    .feature_section .dot_2 {
        top: 340px;
        left: 40px;
    }

    .showcase_section .mobile_frame {
        width: 295px;
    }

    .chat_banner .banner_image {
        max-width: 300px;
        margin: 0 auto;
    }

    .chat_banner .banner_image .bar_image {
        max-width: 220px;
    }

    .chat_banner .banner_image .bar_1 {
        top: 80px;
        left: -50px;
    }

    .chat_banner .banner_image .bar_2 {
        top: 190px;
        right: -95px;
    }

    .chat_banner .banner_image .bar_3 {
        top: 280px;
        left: -50px;
    }

    .chat_banner .bg_1,
    .chat_banner .bg_2 {
        max-width: 530px;
    }

    .chat_banner .bg_1 {
        top: -95px;
        right: -100px;
    }

    .chat_banner .bg_2 {
        top: -65px;
    }

    .banner_section .banner_content .title_text {
        font-size: 36px;
        margin-bottom: 15px;
    }

    .banner_section .banner_content p {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .digital_agency_banner .banner_image {
        max-width: 380px;
    }

    .digital_agency_banner .shape_1 {
        top: -90px;
        right: -140px;
        max-width: 535px;
    }

    .digital_agency_banner .shape_2 {
        top: -110px;
        right: -190px;
        max-width: 600px;
    }

    .digital_agency_banner {
        min-height: auto;
        padding-top: 200px;
        padding-bottom: 130px;
    }

    .digital_marketing_banner .banner_content {
        max-width: 470px;
        margin: 0 auto 40px;
    }

    .digital_marketing_banner .banner_content p {
        font-size: 13px;
        margin-bottom: 20px;
    }

    .banner_section .banner_content .btns_group>ul>li {
        margin-right: 15px;
    }

    .digital_marketing_banner .banner_image {
        max-width: 370px;
    }

    .digital_marketing_banner {
        min-height: auto;
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .digital_marketing_banner .apps_wrap>ul {
        width: 230px;
        height: 210px;
    }

    .digital_marketing_banner .apps_wrap {
        bottom: 230px;
    }

    .digital_marketing_banner .apps_wrap>ul>li {
        width: 50px;
        height: 50px;
    }

    .digital_marketing_banner .apps_wrap>ul>li>a>img {
        height: 24px;
    }

    .digital_marketing_banner .cloud_image {
        max-width: 50px;
    }

    .digital_marketing_banner .cloud_image.cloud_1 {
        top: 25%;
        left: 5%;
    }

    .digital_marketing_banner .triangle_1 {
        top: 14%;
        left: 14%;
    }

    .digital_marketing_banner .dot_1 {
        top: 36%;
        left: 14%;
    }

    .digital_marketing_banner .dot_2 {
        top: 48%;
        left: 2%;
    }

    .digital_marketing_banner .dot_3 {
        top: 40%;
        left: 30%;
    }

    .digital_marketing_banner .cloud_image.cloud_2 {
        top: 12%;
        right: 4%;
    }

    .digital_marketing_banner .dot_4 {
        top: 20%;
        right: 3%;
    }

    .digital_marketing_banner .triangle_2 {
        top: 20%;
        right: 20%;
    }

    .digital_marketing_banner .cloud_image.cloud_3 {
        top: 30%;
    }

    .digital_marketing_banner .cloud_image.cloud_4 {
        top: 40%;
        right: 22%;
    }

    .digital_marketing_banner .dot_5 {
        top: 32%;
        right: 15%;
    }

    .digital_marketing_banner .dot_6 {
        top: 47%;
        right: 5%;
    }

    .home_digital_marketing .service_primary {
        padding: 0;
    }

    .service_primary .item_title {
        margin-bottom: 15px;
    }

    .service_primary .item_title>a {
        font-size: 22px;
    }

    .service_primary .icon_wrap {
        margin-bottom: 20px;
    }

    .service_primary .item_icon {
        width: 75px;
        height: 75px;
        font-size: 42px;
        line-height: 75px;
    }

    .service_primary p {
        font-size: 13px;
    }

    .home_digital_marketing .section_title .icon_rounded {
        width: 70px;
        height: 70px;
        line-height: 70px;
    }

    .home_digital_marketing .pricing_section {
        padding-bottom: 120px;
    }

    .hr_management_banner .banner_content .title_text span {
        font-size: 36px;
    }

    .hr_management_banner .banner_content {
        max-width: 390px;
    }

    .hr_management_banner .banner_image {
        right: -415px;
        -webkit-transform: scale(0.54);
        -ms-transform: scale(0.54);
        transform: scale(0.54);
    }

    .hr_management_banner {
        min-height: 655px;
    }

    .hr_management_banner .shape_1 {
        max-width: 255px;
    }

    .hr_management_banner .shape_2 {
        max-width: 405px;
    }

    .hr_management_banner .shape_3 {
        max-width: 545px;
    }

    .banner_section .banner_content .popup_video .icon {
        width: 45px;
        height: 45px;
        font-size: 32px;
        line-height: 45px;
    }

    .banner_section .banner_content .popup_video small {
        margin: 9px 0;
    }

    .hr_management_banner .shape_4 {
        top: 50px;
        left: 45%;
    }

    .hr_management_banner .shape_5 {
        left: 30%;
        bottom: -140px;
    }

    .hr_management_banner .shape_4,
    .hr_management_banner .shape_5 {
        max-width: 18px;
    }

    .saas_banner .banner_image {
        width: auto;
        max-width: 670px;
        margin: 0 auto -164px;
    }

    .saas_banner .banner_image .phone_image {
        right: -20px;
        bottom: -12px;
        max-width: 125px;
    }

    .feature_section .shape_14 {
        top: 0;
        left: -340px;
        max-width: 680px;
    }

    .feature_section .shape_15 {
        right: -209px;
        bottom: 100px;
        max-width: 600px;
    }

    .feature_section .feature_image_12 .big_image {
        margin-top: -19px;
        margin-bottom: 0;
    }

    .feature_section .feature_image_12 {
        margin: 0 auto 40px;
    }

    .feature_section .feature_image_12 .small_image {
        max-width: 170px;
    }

    .feature_section .feature_image_12 .child_image_1,
    .feature_section .feature_image_12 .child_image_2,
    .feature_section .feature_image_12 .child_image_3 {
        max-width: 200px;
    }

    .feature_section .feature_image_12 .child_image_1 {
        top: 30px;
    }

    .feature_section .feature_image_12 .child_image_2 {
        top: 83px;
    }

    .feature_section .feature_image_12 .child_image_3 {
        max-width: 240px;
        top: 135px;
    }

    .casestudy_fullimage .item_content {
        padding: 20px;
        padding-bottom: 15px;
    }

    .casestudy_fullimage .details_btn {
        top: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        font-size: 18px;
        line-height: 42px;
    }

    .gallery_section .element_grid.masonry_portfolio.column_4 .element-item,
    .casestudy_section .element_grid.masonry_portfolio.column_4 .element-item,
    .portfolio_section .element_grid.masonry_portfolio.column_4 .element-item {
        width: 33.333%;
    }

    .gallery_section .element_grid.column_4 .element-item,
    .casestudy_section .element_grid.column_4 .element-item,
    .portfolio_section .element_grid.column_4 .element-item {
        width: 33.333%;
    }

    .digital_marketing_banner .banner_content p {
        font-size: 14px;
    }

    .banner_section .banner_content .title_text {
        font-size: 38px;
    }

    .app_landing_banner .banner_content .title_text {
        font-size: 48px;
    }

    .chat_banner .banner_content .title_text {
        font-size: 42px;
    }

    .digital_marketing_banner .banner_content .title_text {
        font-size: 42px;
    }

    .hr_management_banner .banner_content .title_text span {
        font-size: 42px;
    }

    .social_icon>ul>li {
        margin-right: 5px;
    }

    .team_grid .member_name>a {
        font-size: 18px;
    }

    .team_grid .member_image {
        margin-bottom: 12px;
    }

    .team_grid .member_title {
        font-size: 13px;
        display: block;
    }

    .promotion_section .btns_group>ul>li {
        margin-right: 20px;
    }
}

@media screen and (max-width: 820px) {
    .shop_list .item_content {
        padding-top: 0;
        padding-left: 30px;
    }

    .shop_list .item_price {
        margin-bottom: 15px;
    }

    .shop_list p {
        margin-bottom: 30px;
    }

    .shop_list .action_btns>ul>li>button {
        height: 45px;
        padding: 0 25px;
        line-height: 43px;
    }

    .shop_list .action_btns>ul>li>a {
        width: 45px;
        height: 45px;
        line-height: 45px;
    }

    .shop_list .item_image {
        max-width: 370px;
    }

    .cart_section .table_wrap .item_image {
        width: 80px;
        margin-right: 10px;
    }

    .table_wrap .table {
        text-align: right;
    }

    .table_wrap .table thead {
        display: none;
    }

    .table_wrap .table tr {
        float: none;
        width: 100%;
        display: block;
        padding: 0 30px;
        margin-bottom: 30px;
        background-color: #eff2f9;
    }

    .table_wrap .table td {
        width: 100%;
        display: block;
        max-width: 100%;
        border-bottom: 1px solid #ededed;
    }

    .cart_section .table_wrap .table td,
    .cart_section .table_wrap .table th {
        text-align: right;
    }

    .cart_section .table_wrap .table td .product_item,
    .cart_section .table_wrap .table th .product_item {
        display: inline-block;
    }

    .cart_section .table_wrap .table td .product_item .item_image,
    .cart_section .table_wrap .table th .product_item .item_image {
        width: 80px;
        float: right;
        margin-right: 0;
        margin-left: 10px;
    }

    .cart_section .table_wrap .table td:first-child,
    .cart_section .table_wrap .table th:first-child {
        border: none;
        text-align: right;
    }

    .cart_section .table_wrap .td_title {
        display: inline-block;
    }

    .gallery_section .element_grid.column_4 .element-item,
    .casestudy_section .element_grid.column_4 .element-item,
    .portfolio_section .element_grid.column_4 .element-item {
        width: 33.333%;
    }

    .banner_section .banner_content .btns_group>ul>li {
        margin-right: 25px;
    }

    .feature_section .btns_group>ul>li {
        width: 100%;
        display: block;
        margin-right: 0;
        text-align: center;
        margin-bottom: 15px;
    }

    .feature_section .btns_group>ul>li:last-child {
        margin-bottom: 0 !important;
    }

    .section_title .title_text {
        font-size: 30px;
    }

    .app_landing_banner .banner_content .title_text {
        font-size: 44px;
    }

    .chat_banner .banner_content .title_text {
        font-size: 36px;
    }

    .agency_banner .bg_2 {
        width: 740px;
    }

    .agency_banner .bg_1 {
        width: 655px;
    }
}

@media screen and (max-width: 780px) {
    .header_section .menu_btn {
        font-size: 28px;
        margin: 7px 0;
    }
}

@media screen and (max-width: 767px) {
    .btn_text {
        font-size: 16px;
    }

    .btn {
        font-size: 15px;
        padding: 15px 26px;
    }

    .btn.btn_border {
        padding: 14px 26px;
    }

    .mb-80 {
        margin-bottom: 40px;
    }

    .form_item input {
        height: 50px;
    }

    .form_item .input_title {
        margin-bottom: 8px;
    }

    .form_item.dropdown>button {
        height: 50px;
    }

    .form_item.dropdown .dropdown-menu>ul>li>a {
        font-size: 14px;
        padding: 10px 30px;
    }

    .header_section .brand_logo>.brand_link {
        max-width: 115px;
    }

    .header_section .menu_btn {
        font-size: 25px;
        margin: 7px 0;
    }

    .footer_section .footer_bottom {
        text-align: center;
    }

    .footer_section .copyright_text {
        margin-bottom: 15px;
    }

    .footer_section .footer_menu>ul {
        float: none;
        margin: 15px auto 0;
    }

    .breadcrumb_section .breadcrumb_content .page_title {
        font-size: 48px;
    }

    .sidebar_section.pl-30 {
        padding: 0;
    }

    .error_section .error_content p {
        max-width: 590px;
    }

    .error_section .spahe_1,
    .error_section .spahe_2 {
        max-width: 330px;
    }

    .error_section .error_content .page_title {
        font-size: 250px;
    }

    .error_section .error_content .title_text {
        font-size: 38px;
    }

    .service_boxed_1 {
        max-width: 370px;
        margin: 30px auto 0;
    }

    .service_section .spahe_1 {
        right: 70px;
        max-width: 140px;
    }

    .service_section .spahe_2 {
        right: 15px;
        max-width: 130px;
    }

    .newsletter_section .newsletter_boxed .shape_1 {
        bottom: 60px;
        max-width: 140px;
    }

    .newsletter_section .newsletter_boxed .shape_2 {
        right: 40px;
        bottom: 130px;
        max-width: 80px;
    }

    .newsletter_section .newsletter_boxed {
        padding: 60px;
    }

    .service_section .tab-content .service_image_2 .image_2 {
        right: 0;
        max-width: 190px;
    }

    .introduction_section .video_wrap {
        max-width: 450px;
        margin: 0 auto;
    }

    .introduction_section .video_wrap .shadow_image {
        width: 145%;
    }

    .introduction_section .video_wrap {
        padding-bottom: 40px;
    }

    .introduction_section .video_wrap .dot_image {
        top: -20px;
        right: -18px;
        max-width: 90px;
    }

    .introduction_section .video_wrap .leaf_image {
        left: -50px;
        bottom: 15px;
        max-width: 75px;
    }

    .introduction_section .video_wrap .man_1 {
        right: -80px;
        bottom: 40px;
        max-width: 70px;
    }

    .introduction_section .video_wrap .man_2 {
        left: -100px;
        bottom: 45px;
        max-width: 90px;
    }

    .feature_section .child_feature p {
        font-size: 14px;
    }

    .service_primary_2 {
        max-width: 330px;
        margin: 50px auto 0;
    }

    .owl-theme.arrow_top_right .owl-nav {
        top: -70px;
    }

    .feature_section .section_title .tabs_nav>ul>li>a {
        font-size: 15px;
        padding: 10px 22px;
    }

    .feature_section .section_title .tabs_nav>ul {
        margin: 0 auto;
    }

    .owl-theme.arrow_top_right .owl-nav {
        top: -60px;
    }

    .register_section .signup_wrap {
        background: 0 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0 30px;
    }

    .register_section .signup_wrap .info_wrap,
    .register_section .signup_wrap .signup_form {
        margin: 0;
        padding: 0;
        border: none;
    }

    .register_section .signup_wrap .info_wrap {
        max-width: 380px;
        margin-bottom: 60px;
    }

    .process_section .line_bg {
        background: 0 0 !important;
        padding-bottom: 0;
    }

    .process_section .line_bg:after,
    .process_section .line_bg:before {
        display: none;
    }

    .process_section .process_item {
        margin: 0;
        padding: 0;
        margin-bottom: 70px;
    }

    .process_section .process_item:last-child {
        margin-bottom: 0 !important;
    }

    .process_section .process_item .order-last {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }

    .process_section .process_item .item_content {
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .process_section .process_item .item_content:after,
    .process_section .process_item .item_content:before {
        display: none;
    }

    .process_section .process_item .serial_number {
        margin-top: 0;
        margin-bottom: 35px;
    }

    .process_section .process_item .item_image {
        max-width: 440px;
        margin: 0 auto 30px;
    }

    .pricing_plan_1,
    .pricing_plan_2,
    .pricing_plan_3 {
        max-width: 340px;
        margin: 30px auto 0;
    }

    .apply_section .apply_form .form_item textarea {
        min-height: 150px;
    }

    .map_section .google-map {
        height: 360px;
    }

    .filter-btns-group {
        margin-bottom: 30px;
    }

    .casestudy_section .element_grid {
        margin: -7px;
    }

    .casestudy_section .element_grid .element-item {
        padding: 7px;
    }

    .casestudy_fullimage .item_content .item_title>a {
        font-size: 16px;
    }

    .casestudy_fullimage .item_content .item_category>a {
        font-size: 13px;
    }

    .filter-btns-group>ul>li {
        height: 40px;
    }

    .filter-btns-group>ul>li>button {
        font-size: 14px;
        padding: 0 25px;
        line-height: 40px;
    }

    .details_section .item_image.mr--30 {
        margin: 0;
        margin-bottom: 30px;
    }

    .details_section .item_image.mr--30>img {
        width: 100%;
        display: block;
    }

    .details_section .item_title {
        font-size: 30px;
        margin-bottom: 30px;
    }

    .agency_banner .bg_1 {
        width: 675px;
    }

    .agency_banner .bg_2 {
        width: 795px;
    }

    .feature_section .shape_1 {
        left: -180px;
        max-width: 500px;
    }

    .testimonial_section .shape_2 {
        max-width: 300px;
    }

    .partner_section .shape_1 {
        max-width: 150px;
    }

    .app_landing_banner .banner_content .title_text {
        font-size: 36px;
        margin-bottom: 15px;
    }

    .banner_section .banner_content p {
        font-size: 13px;
        margin-bottom: 20px;
    }

    .app_landing_banner .banner_image .phone_1 {
        max-width: 270px;
    }

    .app_landing_banner .banner_image .phone_2 {
        max-width: 224px;
        right: -35px;
    }

    .app_landing_banner .banner_image .shape_1 {
        width: 375px;
        top: 0;
        left: -25px;
    }

    .service_section .section_title {
        text-align: center;
    }

    .service_section .section_title.mr--30 {
        margin-right: 0;
    }

    .appScreenshot {
        max-width: 530px;
        margin: 0 auto;
    }

    .showcase_section .mobile_frame {
        width: 265px;
    }

    .chat_banner .banner_content .title_text {
        font-size: 32px;
        margin-bottom: 15px;
    }

    .chat_banner .banner_image {
        max-width: 240px;
    }

    .chat_banner {
        padding-top: 120px;
    }

    .chat_banner .banner_image .bar_image {
        max-width: 180px;
    }

    .chat_banner .banner_image .bar_1 {
        top: 55px;
        left: -45px;
    }

    .chat_banner .banner_image .bar_2 {
        top: 155px;
        right: -80px;
    }

    .chat_banner .banner_image .bar_3 {
        top: 220px;
        left: -45px;
    }

    .chat_banner .bg_1,
    .chat_banner .bg_2 {
        max-width: 435px;
    }

    .partner_section .dot_image_1 {
        display: none;
    }

    .introduction_section .video_wrap .leaf_image_1 {
        left: -70px;
    }

    .introduction_section .video_wrap .leaf_image_2 {
        right: -70px;
    }

    .feature_section .feature_image_5 {
        max-width: 340px;
    }

    .feature_section .feature_image_5 .image_2,
    .feature_section .feature_image_5 .image_3 {
        max-width: 220px;
    }

    .feature_section .feature_image_5 .image_2 {
        bottom: 40px;
        left: -105px;
    }

    .feature_section .feature_image_5 .image_3 {
        top: -90px;
        right: -65px;
    }

    .feature_section .feature_image_5 .dot_image_1,
    .feature_section .feature_image_5 .dot_image_2 {
        max-width: 100px;
    }

    .feature_section .feature_image_5 .dot_image_2 {
        right: 10px;
        bottom: 125px;
    }

    .feature_section .shape_12 {
        top: 125px;
        max-width: 595px;
    }

    .banner_section .banner_content {
        max-width: 380px;
    }

    .banner_section .banner_content .title_text {
        font-size: 32px;
        margin-bottom: 15px;
    }

    .digital_agency_banner .banner_image {
        max-width: 350px;
    }

    .digital_agency_banner .shape_1 {
        top: -86px;
        right: -135px;
        max-width: 500px;
    }

    .digital_agency_banner .shape_2 {
        top: -110px;
        right: -190px;
        max-width: 570px;
    }

    .digital_marketing_banner .banner_image {
        max-width: 330px;
    }

    .digital_marketing_banner .apps_wrap>ul {
        width: 200px;
        height: 180px;
    }

    .digital_marketing_banner .apps_wrap {
        bottom: 190px;
    }

    .digital_marketing_banner .apps_wrap>ul>li {
        width: 45px;
        height: 45px;
    }

    .digital_marketing_banner .apps_wrap>ul>li>a>img {
        height: 18px;
    }

    .seo_section .shape_1 {
        max-width: 90px;
    }

    .seo_section .glob_image_1 {
        top: -10px;
        max-width: 280px;
    }

    .hr_management_banner .shape_1 {
        max-width: 250px;
    }

    .hr_management_banner .shape_2 {
        max-width: 370px;
    }

    .hr_management_banner .shape_3 {
        max-width: 480px;
    }

    .hr_management_banner .banner_image {
        top: -60px;
        right: -450px;
        -webkit-transform: scale(0.46);
        -ms-transform: scale(0.46);
        transform: scale(0.46);
    }

    .hr_management_banner {
        min-height: auto;
        padding-top: 200px;
        padding-bottom: 140px;
    }

    .hr_management_banner .shape_5 {
        left: 35%;
        bottom: -35%;
    }

    .hr_management_banner .shape_4 {
        top: 30px;
        left: 48%;
    }

    .hr_management_banner .shape_1 {
        max-width: 220px;
    }

    .hr_management_banner .shape_2 {
        max-width: 340px;
    }

    .hr_management_banner .shape_3 {
        max-width: 450px;
    }

    .intregation_section .apps_wrap>ul {
        width: auto;
        height: auto;
    }

    .intregation_section .apps_wrap>ul>li {
        float: none;
        display: block;
        position: static;
        margin-bottom: 15px;
    }

    .payment_banner .shape_1 {
        left: -100px;
        bottom: -50px;
        max-width: 340px;
    }

    .feature_section .child_feature {
        max-width: 320px;
        margin: 50px auto 0;
    }

    .feature_section .shape_9 {
        right: -100px;
        max-width: 320px;
    }

    .feature_section .shape_10 {
        left: -100px;
        max-width: 320px;
    }

    .feature_boxed {
        padding: 35px;
        max-width: 320px;
        padding-top: 65px;
        margin: 60px auto 0;
    }

    .project_management_banner .banner_content {
        max-width: 410px;
    }

    .project_management_banner .banner_image {
        width: auto;
        max-width: 630px;
        margin: 80px auto -100px;
    }

    .banner_section .form_item input {
        height: 50px;
        padding: 0 20px;
    }

    .project_management_banner {
        margin-bottom: 100px;
    }

    .project_management_banner .banner_image .deco_image {
        top: 80px;
        right: 33px;
        max-width: 150px;
    }

    .service_section .tabs_nav_2 {
        margin-bottom: 50px;
    }

    .service_section .tab-content .service_image_3 {
        margin-bottom: -50px;
    }

    .saas_banner .banner_content {
        max-width: 100% !important;
    }

    .saas_banner .banner_image {
        max-width: 570px;
        margin: 0 auto -120px;
    }

    .saas_banner {
        padding-top: 150px;
        margin-bottom: 80px;
    }

    .saas_banner .banner_image .phone_image {
        max-width: 110px;
    }

    .h_map {
        margin-left: -15px;
        margin-right: -15px;
    }

    .h_map ul li .place_name {
        font-size: 10px;
        min-width: auto;
        padding: 0 10px;
        line-height: 20px;
    }

    .h_map ul li:nth-child(1) {
        top: 5%;
        left: 5%;
    }

    .h_map ul li:nth-child(2) {
        top: 30%;
        left: 10%;
    }

    .h_map ul li:nth-child(3) {
        left: 50%;
        top: 20%;
    }

    .h_map ul li:nth-child(4) {
        top: 40%;
        left: 73%;
    }

    .h_map ul li:nth-child(5) {
        top: 10%;
        left: 88%;
    }

    .h_map ul li:nth-child(6) {
        top: 70%;
        left: 15%;
    }

    .h_map ul li:nth-child(7) {
        top: 56%;
        left: 52%;
    }

    .h_map ul li:nth-child(8) {
        top: 79%;
        left: 96%;
    }

    .details_section .details_images_carousel {
        margin-bottom: 30px;
    }

    .gallery_section .element_grid.column_3 .element-item,
    .casestudy_section .element_grid.column_3 .element-item,
    .portfolio_section .element_grid.column_3 .element-item {
        width: 50%;
    }

    .home_chat:not(.home_digital_agency) .footer_section .useful_links,
    .home_chat:not(.home_digital_agency) .footer_section .social_links {
        margin-top: 40px;
        text-align: center;
    }

    .home_chat:not(.home_digital_agency) .widget.about_content {
        margin: 0 auto;
        text-align: center;
    }

    .banner_section .banner_content {
        max-width: 380px;
        margin: 0 auto;
    }

    .service_section .section_title .c_slide_in_wrap1,
    .feature_section .section_title .c_slide_in_wrap1,
    .feature_section .section_title .sub_title {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .hr_management_banner .banner_content {
        margin: 0 !important;
    }

    .project_management_banner .banner_content .title_text {
        font-size: 36px;
    }

    .project_management_banner .banner_content p {
        font-size: 14px;
    }

    .project_management_banner .banner_content {
        max-width: 430px;
        margin: 0 auto;
    }

    .saas_banner .banner_content {
        margin: 0 auto;
        max-width: 480px !important;
    }

    .saas_banner .banner_content p {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .saas_banner .banner_content .title_text {
        margin-bottom: 10px;
    }

    .banner_section .banner_content .title_text {
        font-size: 42px;
    }

    .digital_marketing_banner .banner_content .title_text {
        font-size: 32px;
    }

    .text_effect {
        line-height: 1;
    }

    .banner_section .banner_content p {
        font-size: 14px;
    }

    .digital_marketing_banner .banner_image {
        max-width: 330px;
        margin-top: 40px;
    }

    .payment_banner .banner_content .title_text {
        font-size: 40px;
    }

    .software_banner .banner_content .title_text {
        font-size: 38px;
    }

    .section_title .sub_title {
        font-size: 15px;
    }

    .section_title .title_text,
    .section_title.increase_size .title_text,
    .feature_section .section_title .title_text,
    .feature_section .section_title.increase_size .title_text {
        font-size: 30px;
    }

    .section_title p {
        font-size: 15px;
    }

    .header_section .brand_logo>.brand_link,
    .sticky_header.stuck .brand_logo>.brand_link {
        max-width: 115px;
    }

    .banner_section .form_item .btn {
        font-size: 14px;
        padding: 12px 26px;
    }
}

@media screen and (max-width: 710px) {
    .agency_banner .bg_2 {
        width: 725px;
    }

    .agency_banner .bg_1 {
        width: 625px;
    }

    .banner_section .banner_content .title_text {
        font-size: 40px;
    }
}




@media screen and (max-width: 710px) {
    .cta-content-img1 {
        display: none;
    }


}

.custom-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: blue;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-button:hover {
    background-color: darkblue;
}

.custom-button span {
    margin: 0 5px;
}


.know-more-div {
    position: relative;
}

.know-more-div .know-more-button {
    display: none;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.know-more-div:hover .know-more-button {
    display: block;
}

.know-more-button {
    width: 130px;
    height: 4vh;
}

.know-more-button-mobile {
    display: none;
}

@media (max-width:768px) {
    .know-more-button-mobile {
        display: block;
        width: 150px;
    }

    .know-more-div:hover .know-more-button {
        display: none;
    }

    .start-chat-button-mobile {
        width: 150px;
    }

    .trending-product-buttons-div {
        display: flex;

        margin-left: 30px;
    }

    .smart_contract_dev_process h2 {
        font-size: 35px;
        text-align: center;
    }

    .why-chhose-us-section {
        margin-left: 15px;
    }

    .browse-our-impactful {
        margin-left: 15px;
    }

    .Ourprocesure-main-div {
        margin-left: 20px;
    }

    .faq_main {
        margin-left: 20px;
    }

    .tell-us-about-div {
        margin-left: 20px;
    }

    .footer-main-div {
        margin-left: 15px;
    }

    .BnnrSecMain {
        margin-left: 25px;
    }

    .AboutUsWhoWeAre-div {
        margin-left: 15px;
    }

    .OurServices-main-div {
        margin-left: 20px;
    }
}

.Browse-impactful {
    text-align: center !important;
}

.custom-class-name {
    display: none;
}


.blockchain-lists li {
    font-size: 14px !important;
}




.blockchain:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: url('../assets/images/prod/blockchain.jpg'); */
    opacity: 1.0;
    background-size: cover;
    z-index: -1;
}



.web:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: url('../assets/images/prod/web.jpg'); */
    opacity: 1.0;
    background-size: cover;
    z-index: -1;
}

.software:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: url('../assets/images/prod/software.jpg'); */
    opacity: 1.0;
    background-size: cover;
    z-index: -1;
}

.Aplication:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: url('../assets/images/prod/image21.jpg'); */
    opacity: 1.0;
    background-size: cover;
    z-index: -1;
}

.games:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: url('../assets/images/prod/image22.jpg'); */
    opacity: 1.0;
    background-size: cover;
    z-index: -1;
}

.metaverse:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: url('../assets/images/prod/image23.jpg'); */
    opacity: 1.0;
    background-size: cover;
    z-index: -1;
}




.footer_right_main {
    position: relative;
    background: #ffffff1f;
    padding: 20px;
    border-radius: 15px;
    margin: 50px 0px;
}


.footer_right_main .title {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 5px;
    text-align: center;
    background: linear-gradient(90deg,
            #cc46fb 0%,
            #4dfff4 38.02%,
            #ffb44a 70.31%,
            #ff00e5 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
}


.footer_right_main .contents {
    text-align: center;
}

.footer_right_main {
    padding: 20px;
}

.footer_right_main .title {
    font-size: 25px;
}

.footer_right_main {
    margin: 0;
}

.fitoutform .form-group {
    margin-bottom: 1em !important;
}

.fitoutform .form-control {
    padding: 0;
    border: none;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid #e5e3e3;
    border-radius: 0;
    box-shadow: none;
}



@media (max-width: 767px) {
    #popup_form_modal .fitoutform .form-group {
        margin-bottom: 0.8em !important;
    }

    .popup-contact-form .btn {
        margin-top: 10px;
        font-size: 14px;
        padding: 10px 35px;
    }

    #popup_form_modal.popup-form .title1 {
        font-size: 30px;

        color: #00f7ff;
    }

    #popup_form_modal.popup-form .popup-contact-form .social_list_sec ul {
        justify-content: center;
    }

    #popup_form_modal.popup-form {
        top: 0px !important;
    }

    #popup_form_modal.popup-form .modal-dialog {
        margin-top: 3.5rem;
    }
}


.form-control {
    background: #262626;
    border-radius: 2px;
    font-weight: 300;
    font-size: 13px;
    line-height: 24px;
    color: #FFFFFF;
    min-height: 44px;
    border: unset;
    box-shadow: unset;
    min-width: 271px;
}

@media (max-width: 1119px) {
    .FtrFrm .form-control {
        min-width: 200px;
    }
}

.form-control {
    padding: 0;
    border: none;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid #e5e3e3;
    border-radius: 0;
    box-shadow: none;
}

.brands-div {
    display: flex;
}

@media(max-width:768px) {
    .brands-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        text-align: center;
        margin-right: 60px;
    }
}

.content button {
    background-color: #059DFF;
    color: white;
    border: 1px solid transparent;
    border-radius: 5px;
    width: 110px;
    height: 7vh;
}

.content {
    height: 30vh;
}

.heading-conatant {
    font-size: 18px;
    color: #059DFF;
}


.main-modal .modal-butt {
    background-color: #03a9f4;
    border: 1px solid transparent;
    border-radius: 10px;
    width: 130px;
    height: 6vh;
    font-size: 15px;
}


@media (max-width:768px) {
    .main-modal .modal-butt {
        padding-top: 10px;
    }
}

.main-modal .modal-butt:hover {
    background-color: blue;
    border: 1px solid transparent;
    border-radius: 10px;
    width: 130px;
    height: 6vh;
    font-size: 15px;
}



.wrappers {
    width: 45%;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 5px;
    color: black;
}

.wrappers .card-input input {
    border: 1px solid #C8D1DC;
    height: 7vh;
    color: black;
    border-radius: 5px;
}

.modal-body {
    width: 1100px;
}

@media (max-width:530px) {
    .modal-body {
        width: 900px;
    }
}


@media (max-width:430px) {
    .modal-body {
        width: 850px;
    }
}

@media (max-width:390px) {
    .modal-body {
        width: 770px;
    }
}

.wrappers textarea {
    border: 1px solid #C8D1DC;
    height: 15vh;
    border-radius: 5px;
    color: black;
}

.card-form__inner {
    padding: 20px;
}

.wrappers input:placeholder-shown {
    color: rgba(0, 0, 0, 0.533);
}

.wrappers textarea:placeholder-shown {
    color: rgba(0, 0, 0, 0.533);
}

.card-form__button {
    height: 7vh;
    width: 120px;
    background-color: #059DFF;
    border: 1px solid transparent;
    color: white;
    border-radius: 5px;
}







.video-icon {
    border: 2px solid white;
    width: 30px;
    height: 30px;
    padding: 4px;
    margin-right: 2px;
    border-radius: 50%;
}




.offers-main {
    background-color: blue;
    /* background-color: #059bff; */
    width: 100%;
    height: 12vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
}


@media (max-width:600px) {}

.offers-main h6 {
    color: white;
    font-size: 17px;
}

.timers-div {
    display: flex;
    color: white;
    gap: 10px;
}

.timers-div button {
    background-color: white;
    width: 50px;
    color: #059DFF;
    border: 1px solid transparent;
    height: 6vh;
    border-radius: 5px;
    cursor: auto;
}

.offers-upper-div {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9;
}

@media (max-width:768px) {
    .display-one {
        font-size: 50px;
    }

    .cd-words-wrapper b {
        font-size: 40px;
    }
}

.header-caption .cd-words-wrapper b {
    font-size: 60px;
}

@media (max-width:500px) {
    .display-one {
        font-size: 30px;
    }

    .cd-words-wrapper b {
        font-size: 23px;
    }


}




.content span {
    font-size: 13px;
    font-weight: 100;
}

.google-meet {
    font-size: 45px;
    color: white;
    font-weight: 600;
}

@media (max-width:600px) {
    .google-meet {
        font-size: 27px;
    }
}

.google-meet:hover {
    color: orange;
    cursor: pointer;
}

.modal-open {
    margin-top: 120px;
}

.successful {
    background-color: rgba(111, 39, 200, 0.953);
    color: white;
    display: flex;
    width: auto;
    height: 46vh;
    flex-direction: column;
    text-align: center;
    gap: 20px;
    align-items: center;
}



.card-formses {
    height: 7vh;
    width: 310px;
    /* background-color: rgb(0, 42, 255); */
    /* background-color: rgb(0, 255, 132); */
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
    border: 1px solid transparent;
    color: var(--color-white);
    border-radius: 30px;
    display: flex;
    align-items: center;
    padding: 5px;
}

@media (max-width:530px) {
    .card-formses {
        font-size: 16px
    }

    .successful {
        height: 40vh;
    }

    .modal-open {
        margin-top: 200px;
    }

    .second-p {
        padding-top: 16px;
    }

}

.second-p {
    padding-top: 10px;
}


.main-p {
    color: yellow;
    font-size: 22px;
}